<template>
  <div class="finalWrapper">
    <md-progress-spinner md-mode="indeterminate" :md-diameter="50" :md-stroke="3"></md-progress-spinner>
    <div class="redeeming">Success!...</div>
    <div class="redeeming2">Redirecting you to NEOU</div>
  </div>
</template>

<script>
export default {
  name: "Final",
  data: () => ({}),
  beforeMount: function() {
    document.cookie = "authToken=" + this.$cookie.get("getUserToken") + ";domain=.neoufitness.com;path=/"
    setTimeout(() => {
      window.location.href = "https://app.neoufitness.com/onboard-profile";
    }, 4500);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.finalWrapper {
  text-align: center;
  margin-top: 25vh;
}
.redeeming {
  font-size: 24px;
  font-weight: 500;
  margin: 40px 0px;
}
.redeeming2 {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0px;
}
.md-progress-spinner {
  stroke: #56fffe;
}
</style>
