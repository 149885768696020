<template>
  <div id="app" class="neouCheckout" :class="[screens[plansIndex].toLowerCase(), checkoutVersion]">
    <div class="scrollable">
      <div class="checkoutOverlay"></div>
      <div class="checkoutBox">
        <div class="closeIcon" @click="close"><span>+</span></div>
        <component
          class="checkoutBoxInner"
          v-bind:is="activeComponent"
          @nextScreen="handleNextScreen"
          @selectedPlan="handleSelectedPlan"
          @warningMessage="warningMessage"
          @setUser="setUser"
          @setEmail="setEmail"
          @setStripeId="setStripeId"
          @revertCheckout="handleRevertCheckout"
          v-bind:selectedPlan="selectedPlan"
          v-bind:user="user"
          v-bind:email="email"
          v-bind:stripeId="stripeId"
        ></component>
        <div v-if="messages" class="messageBox">{{ messages }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./components/Login.vue";
import Checkout from "./components/Checkout.vue";
import Plans from "./components/Plans.vue";
// import PlanVariantC from "./components/PlanVariantC.vue";
import Onboarding from "./components/Onboarding.vue";
import Redeem from "./components/Redeem.vue";
import Final from "./components/Final.vue";
import FirstRun from "./components/FirstRun.vue";

import LightLogin from "./components/LightLogin.vue";
import LightCheckout from "./components/LightCheckout.vue";
import LightFinal from "./components/LightFinal.vue";
// Special Stuff
import JanCheckout from "./components/JanCheckout.vue";
import ModernCheckout from "./components/ModernCheckout.vue";

export default {
  name: "App",
  components: {
    Login,
    LightLogin,
    Plans,
    Checkout,
    LightCheckout,
    Onboarding,
    Redeem,
    Final,
    LightFinal,
    FirstRun,
    JanCheckout,
    ModernCheckout
  },
  data: () => ({
    screens: ["Login", "Onboarding", "Plans", "Checkout", "FirstRun", "Final"],
    plansIndex: 0,
    selectedPlan: "",
    messages: "",
    show: false,
    user: "",
    email: "",
    stripeId: "",
    checkoutVersion: window.checkoutVersion || "default"
  }),
  beforeMount: function() {
    if ('neouFlow' in window){
      this.screens = window.neouFlow;
    }
    window.addEventListener('checkoutBack', this.checkoutBack);
  },
  methods: {
    handleNextScreen: function() {
      // Check if the user has been identified when we change page, if not try to identify them
      if (analytics.user().id() == null && this.user !== "" && this.email !== "" ) {  
        window.analytics.identify(this.user, {
          email: this.email,
          user_group: "No Fitness Profile",
          initial_platform: "Web",
        });  
      }
      console.error('reidentified');
      this.screens = window.neouFlow || [
        "Login",
        "Onboarding",
        "Plans",
        "Checkout",
        "FirstRun",
        "Final"
      ];
      this.plansIndex++;
      document.querySelector('body').classList.remove(this.screens[this.plansIndex - 1]);
      document.querySelector('body').classList.add(this.screens[this.plansIndex]);
    },
    checkoutBack(){
      this.plansIndex = this.plansIndex - 1;
      document.querySelector('body').classList.remove(this.screens[this.plansIndex - 1]);
      document.querySelector('body').classList.add(this.screens[this.plansIndex]);
    },
    handleRevertCheckout: function(){
      this.screens = ['LightLogin', 'LightCheckout', 'LightFinal'];
      this.plansIndex = 1;
    },
    handleSelectedPlan: function(planId) {
      this.selectedPlan = planId;
    },
    setUser: function(userId) {
      console.log(userId);
      this.user = userId;
    },
    warningMessage: function(message) {
      console.log(message);
      var self = this;
      self.messages = message;
      setTimeout(function() {
        self.messages = "";
      }, 5 * 1000);
    },
    setEmail: function(email) {
      this.email = email;
    },
    setStripeId: function(stripeId) {
      this.stripeId = stripeId;
    },
    close:function(){
      var checkoutClosedEvent = new Event("checkoutClosed");
      window.dispatchEvent(checkoutClosedEvent);
    }
  },
  computed: {
    activeComponent: function() {
      return this.screens[this.plansIndex];
    }
  }
};
</script>

<style lang="scss">

.neouCheckout {
  position: fixed;
  overflow-y: scroll;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  align-items: flex-start;


}
.light{
  font-family:'Prox';

  .checkoutBox {
    min-height: 620px;
    height: initial;
    width: 100%;
    max-width:530px;
    border-radius: 0px;
    position: relative;
    color: #ffffff;
    display: flex;
    justify-content: center;
    background: #ffffff;
    //overflow: hidden;
  }

 .checkoutOverlay{
    display:none;
  }
.scrollable {
  justify-content: center;
  min-height: 800px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height:100%;
}
.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 20;
  font-size:35px;
  color:#000000;
  &:hover {
    opacity: 0.6;
  }
  span{
    display:block;
    transform:rotate(-45deg);
  }
}
.neouButton {
  width: 100%;
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none;
  line-height: 40px;
  font-size: 13px;
  background: linear-gradient(45deg, #22d6fe, #51fbfe);
  margin-top: 24px;
  font-family: "prox", Helvetica, Arial, sans-serif;
  line-height: 20px;
  border-color: black;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: uppercase;
  box-sizing: border-box;
  font-weight: bold;
  position: relative;
  margin: 0;
  .md-icon {
    position: absolute;
    left: -45px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 100;
  }
  &.facebook {
    width: 100%;
    height: 40px;
    border: none;
    font-size: 13px;
    border-radius: 6px;
    border-color: black;
    background: #4267b2;
    color: #ffffff;
    margin: 0px;
    margin-bottom: 20px;
    text-transform: none;
  }
  &.apple {
    background: #000;
    font-size: 13px;
    text-transform: none;
    color:#fff;
  }
}
.messageBox {
  position: fixed;
  left: 15px;
  bottom: 30px;
  border-radius: 4px;
  padding: 15px 20px;
  background-color: rgb(49, 49, 49);
  z-index: 9;
}
.checkoutOverlay{
  background:rgba(5,6,6,0.8);
  width:100%;
  height:100%;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
}

// DEFAULT STYLES

}
.default{
  .checkoutBox {
  max-height: 728px;
  min-height: 720px;
  height: 100vh;
  width: 100%;
  border-radius: 6px;
  margin: 0px 30px 30px 30px;
  position: relative;
  color: #ffffff;
  display: flex;
  justify-content: center;
  background: rgb(8, 11, 11);
  //overflow: hidden;
}
.scrollable {
  justify-content: center;
  min-height: 800px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 20;
  font-size:35px;
  color:#FFFFFF;
  &:hover {
    opacity: 0.6;
  }
  span{
    display:block;
    transform:rotate(-45deg);
  }
}
.neouButton {
  width: 100%;
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none;
  line-height: 40px;
  font-size: 13px;
  background: linear-gradient(45deg, #22d6fe, #51fbfe);
  margin-top: 24px;
  font-family: "prox", Helvetica, Arial, sans-serif;
  line-height: 20px;
  border-color: black;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: uppercase;
  box-sizing: border-box;
  font-weight: bold;
  position: relative;
  margin: 0;
  .md-icon {
    position: absolute;
    left: -45px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 100;
  }
  &.facebook {
    width: 100%;
    height: 40px;
    border: none;
    font-size: 13px;
    border-radius: 6px;
    border-color: black;
    background: #4267b2;
    color: #ffffff;
    margin: 0px;
    margin-bottom: 20px;
    text-transform: none;
  }
  &.apple {
    background: #fff;
    font-size: 13px;
    text-transform: none;
  }
}
.messageBox {
  position: fixed;
  left: 15px;
  bottom: 30px;
  border-radius: 4px;
  padding: 15px 20px;
  background-color: rgb(49, 49, 49);
  z-index: 9;
}
}

@media screen and (max-width:380px) {
  .light{
    .checkoutBox{
      margin-left:5px;
      margin-right:5px;
    }
  }
}

@media screen and (min-width:540px){
  .light{
    .checkoutBox{
      margin:50px 20px 20px;
      border-radius:6px;
    }
  }
}
</style>
