<template>
  <div class="onboardingWrapper">
    <div class="onboardingHeader">
      <div class="onboardingHeaderWrapper">
        <!-- <md-icon class="backIcon">west</md-icon> -->
        <p class="onboardingHeading">NEOU TIPS</p>
      </div>
    </div>
    <ul class="dots" >
      <li class="dot" v-for="(dot, index) in steps" :class="{active: index == step}"></li>
    </ul>
    <div class="questionScreen" v-if="!lastStep">
      <p class="questionHeading">{{ steps[step].title }}</p>
      <img class="tipsImg" :src="steps[step].image">
      <p class="topParagraph" v-html="steps[step].topParagraph"></p>
      <p class="bottomParagraph" v-if="steps[step].bottomParagraph" v-html="steps[step].bottomParagraph"></p>
      <md-button
        type="button"
        @click.native="nextQuestion"
        class="md-raised neouButton"
        >{{steps[step].button}}</md-button
      >
    </div>
    <div class="questionScreen lastQuestion" v-if="lastStep">
      <p class="questionHeading">{{ steps[step].title }}</p>
      <p class="topParagraph" v-html="steps[step].topParagraph"></p>
      <img class="tipsImg last" :src="recommendation.graphics['16x9']">
      <p class="programTitle">{{recommendation.title}}</p>
      <p class="programKicker">{{recommendation.kicker}}</p>
      <p class="classCount">{{recommendation.classCount}} Classes</p>
      <md-button
        type="button"
        @click.native="goToProgram"
        class="md-raised neouButton"
        >Start This Program</md-button
      >
      <a href="https://app.neoufitness.com/neou/home" class="homepageLink">Go to my custom homepage</a>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { MdField, MdIcon } from "vue-material/dist/components";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import "@/assets/tweaked-vue-material.min.css";

Vue.use(MdField);
Vue.use(MdIcon);

export default {
  name: "FirstRun",
  mixins: [validationMixin],
  data: () => ({
    step: 0,
    steps:[
      {
        title: "Thanks for sharing!",
        image: "https://app.neoufitness.com/8b0e46c6fd9fcdc4f6efa6e986286df2.png",
        topParagraph: "Your home page will be customized with classes, concepts, and programs we recommend for you.",
        bottomParagraph: "Next, we'll share a few tips to help you get started.",
        button: "Let's Go"
      },
      {
        title: "Find your perfect workout",
        image: "https://res.cloudinary.com/dd1ldy5zw/image/upload/v1610504440/first-run-filter_rprmjq.png",
        topParagraph: "Use the filter feature to find the right class for you.<br>Filter by level, genre, duration, equipment, and more.",
        bottomParagraph: false,
        button: "Next Tip"
      },
      {
        title: "Track your progress",
        image: "https://app.neoufitness.com/e431c69f6658999ba80d032579c68b09.png",
        topParagraph: "Complete a program, challenge, or hit a workout milestone to earn badges!",
        bottomParagraph: "You can also track your progress towards your weekly goal on your homepage.",
        button: "Next Tip"
      },
      {
        title: "Let's get this party started!",
        image: "url",
        topParagraph: "Kick things off (and stay on track!) with this hand-picked workout plan based on your goal.",
        bottomParagraph: "You can also track your progress towards your weekly goal on your homepage",
        button: "Next Tip"
      }
    ],
    recommendation: {}
  }),
  mounted() {
    document.cookie = "authToken=" + this.$cookie.get("getUserToken") + ";domain=.neoufitness.com;path=/"
    this.steps.forEach((val, index) => {
      console.log( val );
      let image = new Image();
      image.src = val.image;
    });
    axios
      .get("https://api.neoufitness.com/neou/v1/get-recommend", {
        headers: {
          Authorization: "JWT " + this.$cookie.get("token"),
          token: this.$cookie.get("getUserToken")
        }
      })
      .then(res => {
        this.recommendation = res.data.result;
      })
      .catch(err => {
        console.log(err.response);
      });
  },
  methods: {
    nextQuestion: function() {
      // Track onboarding
      window.analytics.track("Tips", {
        screen_index: this.step - 1
      });
      this.step = this.step + 1;
    },
    goToProgram:function(){
      window.location.href = "https://app.neoufitness.com/neou/programs" + this.recommendation.permaLink;
    }
  },
  computed: {
    activeQuestion: function() {
      return this.questions[this.step];
    },
    lastStep: function() {
      return this.step == this.steps.length - 1;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.topParagraph,
.bottomParagraph{
  text-align:center;
  font-size:16px;
  line-height:19px;
}
.programTitle{
  font-weight:600;
  font-size:16px;
  margin:10px 0px 0px 0px;
}
.programKicker{
  font-size:12px;
  margin:0px;
  color:#CDD3D8;
}
.classCount{
  font-size:12px;
  color:#CDD3D8;
  margin:0px 0px 20px 0px;
}
.dots{
  display:inline-block;
  list-style:none;
  padding:0px;
  text-align:center;
  width:100%;
  margin:30px auto 30px;
}
.homepageLink{
  display:block;
  margin-top:20px;
  font-size:16px;
  color:#56FFFE;

  &:hover{
    text-decoration:underline;
  }
}
.dot{
  width:6px;
  height:6px;
  background-color:#35414A;
  display:inline-block;
  border-radius:100%;
  margin:0px 3px;
  padding:0px;

  &.active{
    width:24px;
    background:linear-gradient(136.07deg, #00BBFF 0%, #56FFFE 100%);
    border-radius:3px;
  }
}
.neouButton{
  min-width:158px;
  display:inline-block;
  margin:0px auto;
  width:auto;
  padding:0px 32px;
}
.tipsImg{
  margin-top:53px;
  border-radius:10px;

  &.last{
    margin-top:10px;
  }
}
.md-field .md-input,
.md-menu.md-select .md-input {
  color: #ffffff;
}
.md-select-value {
  color: #ffffff;
}
.md-field:after {
  border-bottom: 1px solid #35414a;
}
.md-field label {
  color: #8b949b;
  font-style: italic;
}
.md-list-item-text {
  padding: 10px 20px;
}
.md-invalid {
  &.md-field:after {
    border-bottom: 1px solid #ff1c58;
  }

  &.md-field label {
    color: #ff1c58;
  }

  .md-error {
    color: #ff1c58;
  }
}
.forceShow{
  opacity: 1 !important;
    transform: translateZ(0) !important;
    color:#ff1c58;
}
.md-menu-content {
  background-color: #1d2429;
  border-radius: 3px;
  color: #e6eaed;
  cursor: pointer;
}
.onboardingWrapper {
  width: 100%;
}
.onboardingHeaderWrapper,
.questionScreen {
  max-width: 400px;
  margin: 0px auto;
  padding: 0px 15px;
  text-align:center;
}
.questionHeading {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  margin: 0px auto 0px;
  width: 80%;
}
.onboardingHeader {
  border-bottom: 1px solid #35414a;
}
.onboardingHeaderWrapper {
  text-align: center;
  position: relative;
}
.backIcon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.onboardingHeading {
  font-size: 16px;
  text-transform: uppercase;
}
.answerList {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.answer {
  background-color: #ffffff;
  color: #000000;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 10px 30px;
  margin-bottom: 20px;
  cursor: pointer;

  .title {
    font-weight: bold;
    margin: 0px;
    color: #000000;
  }
}
.later {
  text-align: center;
  margin: 20px 0px;
  display: block;
  color: #56fffe;
  font-size: 12px;
  cursor: pointer;
}
.lastQuestion {
  .answerList {
    display: flex;
    justify-content: space-between;
    .goal {
      background-color: #ffffff;
      color: #000000;
      border-radius: 100%;
      font-size: 14px;
      cursor: pointer;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      font-weight: bold;
      font-size: 18px;

      &.active {
        background-color: #56fffe;
      }
    }
  }
}
.goalText {
  color: #56fffe;
  font-size: 25px;
  text-align: center;
  max-width: 200px;
  margin: 50px auto 30px auto;
  line-height: 32px;
}
.encouragement {
  text-align: center;
  max-width: 300px;
  margin: 15px auto;
  font-style: italic;
}
</style>
