import { render, staticRenderFns } from "./LightLogin.vue?vue&type=template&id=5dbdf380&scoped=true&"
import script from "./LightLogin.vue?vue&type=script&lang=js&"
export * from "./LightLogin.vue?vue&type=script&lang=js&"
import style0 from "./LightLogin.vue?vue&type=style&index=0&id=5dbdf380&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dbdf380",
  null
  
)

export default component.exports