<template>
  <div class="login">
    <div class="loginWrapper">
      <div class="mx-auto max-w-md w-full loginScreen" :class="{ submitting: submitting == true }">
        <form v-if="!emailPassed" @submit.prevent="emailSubmit">
          <img src="@/assets/neou-logo.png" class="neouLogo" />
          <h1 class="signIn">
            Get Started
            <br />Enter your email address
          </h1>
          <md-field :class="getValidationClass('email')">
            <label for="email">Email Address</label>
            <md-input name="email" id="email" autocomplete="email" v-model="form.email" />
            <span class="md-error" v-if="!$v.form.email.required">Email is required</span>
            <span class="md-error" v-else-if="!$v.form.email.email">Invalid email</span>
          </md-field>
          <md-button class="md-raised neouButton" type="submit">
            <md-progress-spinner md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>Continue
          </md-button>
          <div class="socailLoginArea" v-if="!hideSocial">
            <div class="orLine">
              <span>OR</span>
            </div>
            <md-button @click="facebookSignin" class="md-raised neouButton facebook">
              <font-awesome-icon :icon="['fab', 'facebook-f']" size="2x" class="iconFacebook" />Continue with Facebook
            </md-button>
            <md-button @click="appleSignin" class="md-raised neouButton apple">
              <font-awesome-icon :icon="['fab', 'apple']" size="2x" class="iconApple" />Sign in with Apple
            </md-button>
          </div>
          <p class="termsLink">
            By signing up, I agree to NEOU’s
            <a
              href="https://app.neoufitness.com/neou/terms-of-services"
            >Terms of Service</a>
          </p>
        </form>
        <div v-if="emailPassed && isVLUser && !passwordResetSent">
          <img src="@/assets/neou-logo.png" class="neouLogo" />
          <p class="welcomeBack">Welcome Back</p>
          <h1 class="signIn">
            Please reset your password to access the
            <em>new</em> NEOU!
          </h1>
          <ul class="featureList">
            <li class="feature">
              <md-icon class="checkmark">check_circle_outline</md-icon>Set goals
              and track your progress
            </li>
            <li class="feature">
              <md-icon class="checkmark">check_circle_outline</md-icon>Personalized recommendations
            </li>
            <li class="feature">
              <md-icon class="checkmark">check_circle_outline</md-icon>Earn
              badges for your achievements
            </li>
            <li class="feature">
              <md-icon class="checkmark">check_circle_outline</md-icon>New ways
              to search and browse
            </li>
          </ul>
          <md-button
            class="md-raised neouButton"
            type="submit"
            @click="resetPassword"
          >Reset Password</md-button>
        </div>
        <div v-if="emailPassed && isVLUser && passwordResetSent">
          <img src="@/assets/neou-logo.png" class="neouLogo" />
          <h1 class="signIn">Password Reset Sent!</h1>
          <p class="regularText">
            Check your email in the next few minutes and click on the link
            provided, then revisit this page.
          </p>
        </div>
        <form v-if="emailPassed && !isVLUser" @submit.prevent="passwordSubmit">
          <img src="@/assets/neou-logo.png" class="neouLogo" />
          <h1
            v-if="isValidEmail && isUserExist && !isCof"
            class="signIn"
          >Welcome back! Enter your Password to sign in</h1>
          <h1
            v-if="(isValidEmail && !isUserExist) || (isCof)"
            class="signIn"
          >Enter a password to create your account</h1>
          <md-field :class="getValidationClass('password')">
            <label>Password</label>
            <md-input v-model="form.password" type="password"></md-input>
          </md-field>
          <md-button type="submit" class="md-raised neouButton">
            <md-progress-spinner md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner><span v-if="isValidEmail && isUserExist">Sign In</span><span v-if="isValidEmail && !isUserExist">Create Account</span>
          </md-button>
          <div class="forgot" v-if="isValidEmail && isUserExist && !isCof">
            <span class="forgotSuccess" v-if="passwordResetSent">Instructions email sent to {{this.form.email}}</span>
            <div v-else>
              <p>New here? <a class="forgot" @click="resetPassword">Create a password</a></p>
              <p>Forgot Password? <a class="forgot" @click="resetPassword">Reset Here</a></p>
          </div>
          </div>
          <p class="termsLink">
            By signing up, I agree to NEOU’s
            <a
              href="https://app.neoufitness.com/neou/terms-of-services"
            >Terms of Service</a>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  MdField,
  MdButton,
  MdProgress,
  MdIcon
} from "vue-material/dist/components";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import "@/assets/tweaked-vue-material.min.css";
import axios from "axios";
import cryptLib from "@skavinvarnan/cryptlib";
import * as firebase from "firebase";
import { parse } from "query-string";

Vue.use(MdField);
Vue.use(MdIcon);
Vue.use(MdButton);
Vue.use(MdProgress);

export default {
  name: "Login",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: null,
      password: null
    },
    emailPassed: false,
    isUserExist: false,
    isValidEmail: false,
    submitting: false,
    isSocialLogin: false,
    isAppleLogin: false,
    isVLUser: false,
    passwordResetSent: false,
    hideSocial: false,
    isCof: false,
    id: "",
    params: {}
  }),
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  mounted: function() {
    window.addEventListener("checkoutLaunched", this.getParam);
  },
  beforeMount: function() {
    window.dataLayer.push({
      event: "registration_start",
      registration_method: "web"
    });
    this.params = parse(window.location.search);
    // Get Country Code
    this.countryCode = "";
    axios.get("https://ip2c.org/self").then(res => {
      this.countryCode = res.data.split(";")[1];
    });
    // Get email
    this.getParam();
    // reset the auth cookie
    this.$cookie.delete('getUserToken');
  },
  methods: {
    identifyUser: function(userId, type, email) {
      // window.analytics.identify(userId, {
      //   account_type: type,
      //   email: email,
      //   user_group: "No Fitness Profile",
      //   initial_platform: "Web",
      //   country_of_registration: this.countryCode
      // });
      window.dataLayer.push({
        event: "finish_registration",
        email: email,
        registration_method: type
      });
      window.analytics.track("Account Created", {
        account_name: email,
        email: email,
        id: userId
      });
      let body = {};
      if( "partner" in window && typeof window.partner !== "undefined" ){
        if( window.partner == "gympass") {
          body = {
            "b2b_partner_details": {
               "name": "gympass",
               "id": window.neouExternalId
            }};
        } else {
          body = {
            "b2b_partner_details": {
               "name": window.partner,
               "id": Date.now()
            }
          }
        }
        console.log( body);
        axios
          .put("https://api.neoufitness.com/neou/v1/user", body, {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token"),
              token: this.$cookie.get("getUserToken")
            }
          })
          .catch(err => {
            console.log( err.response.data.error );
          });
      }
      // Build our attribution object
      let attr = {};
      if ("utm_source" in this.params) {
        attr["utm_source"] = this.params.utm_source;
      }
      if ("utm_medium" in this.params) {
        attr["utm_medium"] = this.params.utm_medium;
      }
      if ("utm_campaign" in this.params) {
        attr["utm_campaign"] = this.params.utm_campaign;
      }
      if ("utm_term" in this.params) {
        attr["utm_term"] = this.params.utm_term;
      }
      if ("utm_content" in this.params) {
        attr["utm_content"] = this.params.utm_content;
      }
      if (window.partner) {
        attr["b2b_partner"] = window.partner;
      }
      if ("n" in this.params) {
        attr["mktg_page_id"] = this.params.n;
      }
      if ("fl" in this.params) {
        attr["flow_type"] = this.params.fl;
      }
      if ("variant" in this.params) {
        attr["variant"] = this.params.variant;
      }
      if ("clickId" in this.params) {
        attr["pj_click_id"] = this.params.clickId;
      }
      // We always have an email at least, then fire it off
      attr["account_name"] = this.form.email;
      window.analytics.track("Registration Attribution", attr);
    },

    facebookSignin: function() {
      var self = this;
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope("email");
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const token = result.credential.accessToken;
          const userEmail =
            result &&
            result.additionalUserInfo &&
            result.additionalUserInfo.profile &&
            result.additionalUserInfo.profile.email;
          const userData = {
            email: userEmail,
            token: token,
            isSocialLogin: true,
            isAppleLogin: false
          };
          if (userEmail) {
            setTimeout(() => {
              self.verifyEmailAction(userData);
            }, 1000);
          } else {
            this.$emit(
              "warningMessage",
              "Need a valid email id asssosiated with Facebook"
            );
          }
        })
        .catch(function(error) {
          console.log(error);
          this.$emit("warningMessage", "Invalid or missing credentials");
        });
    },

    appleSignin: function() {
      var self = this;
      const provider = new firebase.auth.OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          console.log(result);
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const token = result.credential.accessToken;
          const userEmail =
            result &&
            result.additionalUserInfo &&
            result.additionalUserInfo.profile &&
            result.additionalUserInfo.profile.email;
          const userData = {
            email: userEmail,
            token: token,
            isSocialLogin: false,
            isAppleLogin: true
          };
          if (userEmail) {
            setTimeout(() => {
              self.verifyEmailAction(userData);
            }, 1000);
          } else {
            this.$emit(
              "warningMessage",
              "Need a valid email id asssosiated with Apple"
            );
          }
        })
        .catch(err => {
          console.log(err);
          this.$emit(
            "warningMessage",
            err.message || "Invalid or missing credentials"
          );
        });
    },

    verifyEmailAction(userData) {
      var self = this;
      axios
        .post(
          "https://api.neoufitness.com/neou/v1/user/verifyEmail",
          {
            email: userData.email
          },
          {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token")
            }
          }
        )
        .then(res => {
          console.log(res);
          this.isUserExist = res.data.isUserExist;
          this.isValidEmail = res.data.isValidEmail;
          this.isSocialLogin = res.data.isSocialLogin;
          this.isAppleLogin = res.data.isAppleLogin;
          if (this.isValidEmail) {
            if (this.isUserExist) {
              if (this.isSocialLogin) {
                self.loginUsingFB(userData);
              } else if (this.isAppleLogin) {
                self.loginUsingApple(userData);
              } else {
                this.$emit(
                  "warningMessage",
                  "The email address you provided is already in use. Please signin by entering the email address."
                );
              }
            } else {
              if (userData.isSocialLogin) {
                self.createUser(userData);
              } else if (userData.isAppleLogin) {
                self.createUserApple(userData);
              }
            }
          } else {
            this.$emit("warningMessage", "Please enter valid email.");
          }
        })
        .catch(err => {
          console.log(err.message);
          this.$emit("warningMessage", "Please enter a real email address");
        });
    },

    createUserApple(action) {
      var self = this;
      const data = {
        email: action.email,
        token: action.token,
        isAppleLogin: true,
        registeredFrom: "web",
        deviceName: "web"
      };
      axios
        .post(`https://api.neoufitness.com/neou/v1/user`, data, {
          headers: {
            Authorization: "JWT " + this.$cookie.get("token")
          }
        })
        .then(res => {
          self.loginUsingApple(action);
          setTimeout(() => {
            self.identifyUser(res.data.id, "Apple", data.email);
          }, 2000);
        })
        .catch(err => {
          console.log(err.message);
          this.$emit("warningMessage", "Can’t create a user. Try again later.");
        });
    },

    createUser(data) {
      var self = this;
      const getData = {
        email: data.email,
        token: data.token,
        isSocialLogin: true,
        registeredFrom: "web",
        deviceName: "web"
      };
      axios
        .post(
          "https://api.neoufitness.com/neou/v1/user/",
          {
            email: data.email,
            token: data.token,
            isSocialLogin: true,
            registeredFrom: "web",
            deviceName: "web"
          },
          {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token")
            }
          }
        )
        .then(res => {
          self.loginUsingFB(getData);
          setTimeout(() => {
            self.identifyUser(res.data.id, "Facebook", data.email);
          }, 2000);
        })
        .catch(err => {
          console.log(err.message);
          this.submitting = false;
          this.$emit("warningMessage", "Can’t create a user. Try again later.");
        });
    },
    loginUsingFB(data) {
      var self = this;
      axios
        .post(
          "https://api.neoufitness.com/neou/v1/user/login",
          {
            email: data.email,
            token: data.token,
            isSocialLogin: true
          },
          {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token")
            }
          }
        )
        .then(res => {
          const getUser = res.data.userData;
          this.$cookie.set("authToken", data.token, 60 * 60 * 24 * 365, '.neoufitness.com');
          if (this.isUserExist) {
            window.dataLayer.push({
              event: "finish_signin",
              email: getUser.email,
              registration_method: "web"
            });
          }
          self.getUserProfile();
          self.getUser();
          // this.$emit("nextScreen");
        })
        .catch(error => {
          if (error && error.data && error.data.responseCode === 400) {
            this.$emit("warningMessage", "Login failed try after sometime");
          } else if (error && error.data && error.data.responseCode === 401) {
            let errorKey = error && error.data && error.data.error;
            if (errorKey === "INVALID_PASSWORD") {
              this.$emit("warningMessage", "Password entered is Incorrect.");
            } else if (errorKey === "Max Device Limit reached.") {
              this.$emit("warningMessage", error.data.error);
            } else if (errorKey === "EMAIL_NOT_FOUND") {
              this.$emit("warningMessage", "Invalid/UnAuthorized User");
            }
          } else if (error && error.data && error.data.responseCode === 400) {
            this.$emit(
              "warningMessage",
              "Max Device Limit reached. Please try after sometime"
            );
          } else {
            this.$emit("warningMessage", "Invalid Username/Password");
          }
        });
    },

    loginUsingApple(data) {
      var self = this;
      axios
        .post(
          "https://api.neoufitness.com/neou/v1/user/login",
          {
            email: data.email,
            token: data.token,
            isAppleLogin: true
          },
          {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token")
            }
          }
        )
        .then(res => {
          const getUser = res.data.userData;
          this.$cookie.set("authToken", data.token, 60 * 60 * 24 * 365, '.neoufitness.com');
          if (this.isUserExist) {
            window.dataLayer.push({
              event: "finish_signin",
              email: getUser.email,
              registration_method: "web"
            });
          }
          self.getUserProfile();
          self.getUser();
          // this.$emit("nextScreen");
        })
        .catch(error => {
          if (error && error.data && error.data.responseCode === 400) {
            this.$emit("warningMessage", "Login failed try after sometime");
          } else if (error && error.data && error.data.responseCode === 401) {
            let errorKey = error && error.data && error.data.error;
            if (errorKey === "INVALID_PASSWORD") {
              this.$emit("warningMessage", "Password entered is Incorrect.");
            } else if (errorKey === "Max Device Limit reached.") {
              this.$emit("warningMessage", error.data.error);
            } else if (errorKey === "EMAIL_NOT_FOUND") {
              this.$emit("warningMessage", "Invalid/UnAuthorized User");
            }
          } else if (error && error.data && error.data.responseCode === 400) {
            this.$emit(
              "warningMessage",
              "Max Device Limit reached. Please try after sometime"
            );
          } else {
            this.$emit("warningMessage", "Invalid Username/Password");
          }
        });
    },

    getUserProfile() {
      // get user profile
      axios
        .get("https://api.neoufitness.com/neou/v1/user/profile", {
          headers: {
            Authorization: "JWT " + this.$cookie.get("token"),
            token: this.$cookie.get("getUserToken")
          }
        })
        .then(res => {
          const getQuestion = res.data.result;
          if (
            getQuestion.fitnessProfileQA &&
            getQuestion.fitnessProfileQA.length !== 0
          ) {
            console.log("test");
            // window.neouFlow.splice(window.neouFlow.indexOf("Onboarding"), 1);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    getUser() {
      // get user
      axios
        .get("https://api.neoufitness.com/neou/v1/user", {
          headers: {
            Authorization: "JWT " + this.$cookie.get("token"),
            token: this.$cookie.get("getUserToken")
          }
        })
        .then(res => {
          const getQuestion = res.data.result;
          if (
            getQuestion.fitnessProfileQA &&
            getQuestion.fitnessProfileQA.length !== 0
          ) {
            window.neouFlow = ["Login", "Plans", "Checkout", "Final"];
          }
          const getSubs = res.data.result;
          if (getSubs.subscription.subscriptionStatus == 1) {
            this.submitting = false;
            this.$emit(
              "warningMessage",
              "You have already an active subscription"
            );
            document.cookie = "authToken=" + this.$cookie.get("getUserToken") + ";domain=.neoufitness.com;path=/"
            window.location.href="https://app.neoufitness.com/neou/home"
          } else {
            this.$emit("setUser", res.data.id);
            this.$emit("setEmail", this.form.email);
            this.$emit("setStripeId", res.data.result.stripeId);
            setTimeout(() => {
              this.$emit("nextScreen");
            }, 1000);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getParam() {
      let mailId = window.Email;
      if (mailId) {
        const res = window.Responses;
        this.form.email = mailId;
        this.emailPassed = true;
        this.isValidEmail = res.isValidEmail;
        this.isUserExist = res.isUserExist;
        if (this.isUserExist) {
          // this.isCof = true; // Testing
          if( this.form.email.includes("@capitalone.com") ) {
            this.isCof = true;
          }
        }
      }
      // Process some configs
      if ("neouHideSocial" in window) {
        this.hideSocial = true;
      }
    },

    getValidationClass: function(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },

    emailSubmit() {
      this.submitting = true;
      this.$v.form.email.$touch();
      if (!this.$v.form.email.$invalid) {
        axios
          .post(
            "https://api.neoufitness.com/neou/v1/user/verifyEmail",
            {
              email: this.form.email
            },
            {
              headers: {
                Authorization: "JWT " + this.$cookie.get("token")
              }
            }
          )
          .then(res => {
            if(res.data.isCapitalOneUser){
              window.open("https://api.neoufitness.com/neou/v1/sso/callback", "_self");
              return;
            }
            if (res.data.isAppleLogin) {
              this.$emit(
                "warningMessage",
                'You logged in with Apple Sign-In before. Please click "Sign in with Apple"'
              );
              this.submitting = false;
            } else if (res.data.isSocialLogin) {
              this.$emit(
                "warningMessage",
                'You logged in with Facebook before. Please click "Continue with Facebook"'
              );
              this.submitting = false;
            } else {
              this.isUserExist = res.data.isUserExist;
              this.isValidEmail = res.data.isValidEmail;
              this.isVLUser = res.data.isVLUser;
              this.emailPassed = true;
              this.submitting = false;
              if (this.isUserExist) {
                this.userId = res.data.id;
                // this.isCof = true; // Testing
                if( this.form.email.includes("@capitalone.com") ) {
                  this.isCof = true;
                }
              }
            }
          })
          .catch(err => {
            console.log(err.message);
            this.submitting = false;
          });
      } else {
        this.submitting = false;
      }
    },
    resetPassword() {
      axios
        .post(
          "https://api.neoufitness.com/neou/v1/user/resetPwdLink",
          {
            email: this.form.email
          },
          {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token")
            }
          }
        )
        .then(res => {
          this.passwordResetSent = true;
          window.analytics.track("Forgot Password", {
            email: this.form.email,
            id: this.userId,
            token: res.data.link
          });
          this.$emit("setUser", this.userId);
        })
        .catch(err => {
          console.log(err.message);
        });
    },
    passwordSubmit() {
      this.submitting = true;
      this.$v.form.password.$touch();
      if (!this.$v.form.password.$invalid) {
        //console.log(this.form.password);
        let api = "";
        let body = {};
        let cryptPassword = cryptLib.encryptPlainTextWithRandomIV(
          this.form.password,
          "DWIzFkO22qfVMgx2fIsxOXnwz10pRuZfFJBvf4RS3eY="
        );
        //DWIzFkO22qfVMgx2fIsxOXnwz10pRuZfFJBvf4RS3eY=
        //console.log(cryptPassword);
        // IF COF, RESET THE USERS PASSWORD
        if( this.isCof ) {
          console.log( 'here' );
          //console.log(this.form.password);
          let oldPassword = cryptLib.encryptPlainTextWithRandomIV(
            "Neou@123",
            "DWIzFkO22qfVMgx2fIsxOXnwz10pRuZfFJBvf4RS3eY="
          );
          api = "https://api.neoufitness.com/neou/v1/user/login";
          body = {
            email: this.form.email,
            password: oldPassword
          };
          axios
            .post(api, body, {
              headers: {
                Authorization: "JWT " + this.$cookie.get("token")
              }
            })
            .then(res => {
              this.$cookie.set("getUserToken", res.data.token);
              api = 'https://api.neoufitness.com/neou/v1/user/update-password';
              body = {
                oldPassword: oldPassword,
                newPassword: cryptPassword,
                email: this.form.email
              }
            });
        } else if (this.isUserExist === false && this.isValidEmail === true) {
          api = "https://api.neoufitness.com/neou/v1/user/";
          if ("neouExternalId" in window ) {
            body = {
              email: this.form.email,
              password: cryptPassword,
              registeredFrom: "web",
              b2b_partner_details: {
                 name: "gympass",
                 id: window.neouExternalId
              }
            };
          } else {
            body = {
              email: this.form.email,
              password: cryptPassword,
              registeredFrom: "web"
            };
          }
        } else if (this.isUserExist && this.isValidEmail) {
          api = "https://api.neoufitness.com/neou/v1/user/login";
          body = {
            email: this.form.email,
            password: cryptPassword
          };
        }
        axios
          .post(api, body, {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token")
            }
          })
          .then(res => {
            this.$cookie.set("getUserToken", res.data.token);
            if (this.isUserExist) {
              window.dataLayer.push({
                event: "finish_signin",
                email: this.form.email,
                registration_method: "web"
              });
            } else {
              this.identifyUser(res.data.id, "NEOU", this.form.email);
            }
            // get user profile
            axios
              .get("https://api.neoufitness.com/neou/v1/user/profile", {
                headers: {
                  Authorization: "JWT " + this.$cookie.get("token"),
                  token: this.$cookie.get("getUserToken")
                }
              })
              .then(res => {
                // const getQuestion = res.data.result;
                // if (
                //   getQuestion.fitnessProfileQA &&
                //   getQuestion.fitnessProfileQA.length !== 0
                // ) {
                //   if (window.neouFlow.indexOf("Onboarding") > 0) {
                //     window.neouFlow.splice(
                //       window.neouFlow.indexOf("Onboarding"),
                //       1
                //     );
                //   }
                // }
              })
              .catch(err => {
                console.log(err);
                this.$emit("warningMessage", err);
              });
            // get user
            axios
              .get("https://api.neoufitness.com/neou/v1/user", {
                headers: {
                  Authorization: "JWT " + this.$cookie.get("token"),
                  token: this.$cookie.get("getUserToken")
                }
              })
              .then(res => {
                const getQuestion = res.data.result;
                if (
                  getQuestion.fitnessProfileQA &&
                  getQuestion.fitnessProfileQA.length !== 0
                ) {
                  window.neouFlow = ["Login", "Plans", "Checkout", "Final"];
                }
                const getSubs = res.data.result;
                console.log(getSubs);
                if (getSubs.subscription.subscriptionStatus == 1) {
                  this.submitting = false;
                  this.$emit(
                    "warningMessage",
                    "You have already an active subscription"
                  );
                  document.cookie = "authToken=" + this.$cookie.get("getUserToken") + ";domain=.neoufitness.com;path=/"
                  window.location.href="https://app.neoufitness.com/neou/home"
                } else {
                  this.$emit(
                    "setUser",
                    res.data.result.id || res.data.result.userData.id
                  );
                  this.$emit("setEmail", this.form.email);
                  this.$emit("setStripeId", res.data.result.stripeId);
                  this.$emit("nextScreen");
                }
              })
              .catch(err => {
                console.log(err);
              });
          })
          .catch(err => {
            let error = err.response;
            console.log(error);
            if (error && error.data.responseCode === 400) {
              if (error.data.error === "EMAIL_EXISTS") {
                this.$emit(
                  "warningMessage",
                  "User login is registered already!"
                );
              } else {
                this.$emit("warningMessage", "Login failed try after sometime");
              }
            } else if (error && error.data.responseCode === 401) {
              let errorKey = error && error.data.error;

              if (errorKey === "INVALID_PASSWORD") {
                this.$emit("warningMessage", "Password entered is Incorrect.");
              } else if (errorKey === "Max Device Limit reached.") {
                this.$emit("warningMessage", error.data.error);
                // return setPasserrorMsg('Max Device Limit reached.');
              } else if (errorKey === "EMAIL_NOT_FOUND") {
                this.$emit("warningMessage", "Invalid/UnAuthorized User");
              } else if (error && error.data.responseCode === 400) {
                this.$emit(
                  "warningMessage",
                  "Max Device Limit reached. Please try after sometime"
                );
              } else {
                this.$emit("warningMessage", "Invalid Username/Password");
              }
            }
            this.submitting = false;
          });
      } else {
        this.submitting = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.forgot{
  font-style:italic;
  text-align:center;
  margin-top:20px;

  a{
    color:#56fffe;
    cursor:pointer;
  }
}
.submitting {
  .neouButton {
    color: transparent;
  }
  .md-progress-spinner {
    display: inline-flex;
  }
}
.md-progress-spinner {
  stroke: black;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  display: none;
}
.login {
  position: relative;
  max-width: 400px;
  padding: 0px 15px;
  margin: 0px auto;
  width: 100%;
}
.loginWrapper {
  background: url("../assets/neou-login-splash.png") -24px 100% / calc(
      100% + 24px
    ) no-repeat transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 400px;
    top: 120px;
    position: absolute;
    z-index: 1;
    background: linear-gradient(rgb(8, 11, 13) 65%, rgba(8, 11, 13, 0) 100%);
  }
}
.loginScreen {
  width: 280px;
  padding: 70px 0px;
  position: relative;
  z-index: 2;
}
.neouLogo {
  width: 160px;
  margin: 0px auto;
  display: block;
}
.welcomeBack {
  text-align: center;
  font-style: italic;
  font-size: 40px;
}
.featureList {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.feature {
  margin: 5px 0px;
}
.checkmark {
  margin: 0px 10px 0px 0px;
}
.regularText {
  text-align: center;
}
.signIn {
  font-family: "prox", Helvetica;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  margin: 40px 0px 20px 0px;
}
.md-field:after {
  border-bottom: 1px solid #35414a;
}
.md-field label {
  color: #8b949b;
  font-style: italic;
}
.md-field .md-input {
  color: #ffffff;
}
.termsLink {
  font-size: 11px;
  font-style: italic;
  line-height: 14px;
  text-align: center;
  margin: 18px 0px;
  color: rgb(140, 148, 155);

  a {
    color: rgb(86, 255, 254);
  }
}
.md-toggle-password {
  color: #35414a;
}
.orLine {
  overflow: hidden;
  padding: 0px;
  text-align: center;
  color: #35414a;
  line-height: 40px;
  width: 100%;
  &:before,
  &:after {
    background-color: #35414a;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 40%;
    color: #35414a;
  }
  &:before {
    right: 0.5em;
    margin-left: -25%;
  }
  &:after {
    left: 0.5em;
    margin-right: -25%;
  }
  span {
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    padding: 20px;
    color: #8c949b;
    font-weight: bold;
  }
}
.iconApple {
  position: absolute;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
