import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueCookie from "vue-cookie";
import firebase from 'firebase';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faApple, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faApple);
library.add(faFacebookF);

Vue.config.productionTip = false;
Vue.prototype.$plans = window.plans;
Vue.use(VueCookie);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// let app;
var config = {
	apiKey: 'AIzaSyAiQCgtZRAf3EyHDpmoNxeuAfxo9XB4i0Y',
	authDomain: 'neou3-ad673.firebaseapp.com',
	databaseURL: 'https://neou3-ad673.firebaseio.com',
	projectId: 'neou3-ad673',
	storageBucket: 'neou3-ad673.appspot.com',
	messagingSenderId: '1032826540201',
	appId: '1:1032826540201:web:d06c1199923d4eb6fc19b8',
	measurementId: 'G-8511Y3CM0S',
};

firebase.initializeApp(config);
Vue.config.productionTip = false;

window.firebase=firebase;

new Vue({
	render: h => h(App),
	mounted: function() {
		// Only clear out cookies if we're on a marketing page (not the homepage)
		if( "pageType" in window && window.pageType != "homepage" ){
			document.cookie = "authToken=;domain=.neoufitness.com;path=/;max-age=0";
		}
		window.neouCheckout = "v1.0";
		axios
			.post("https://api.neoufitness.com/neou/v1/api/getToken", {
				username: "vuedata",
				password: "aSQQ4M9/Lho8UL8CwYI/qA=="
			})
			.then(res => {
				this.$cookie.set("token", res.data.token);
			})
			.catch(err => {
				console.log(err);
			});
	}
}).$mount("#app");
