<template>
  <div>
    <div class="plansDisplay">
      <div class="cardBlk">
        <div class="cardSec">
          <div
            v-for="plan in offer"
            :key="plan.id"
            :class="[
            {
              cardWrap: !plan.image || !getPlan(plan.id)[0].offerImage['_180wide']
            }
          ]"
          >
            <div
              v-if="plan.image === true && getPlan(plan.id)[0].offerImage['_180wide']"
              class="offerBlk"
              @click="callParent(getPlan(plan.id), plan.name)"
            >
              <img
                class="offerImageDesk"
                :src="getPlan(plan.id)[0].offerImage['_180wide'] "
                alt="loading..."
              />
              <img
                class="offerImageMobile"
                :src="getPlan(plan.id)[0].offerImage['_282wide'] "
                alt="loading..."
              />
            </div>
            <div
              v-if="!plan.image || !getPlan(plan.id)[0].offerImage['_180wide'] "
              class="planWrapper"
            >
              <div class="planHeader">
                <template>
                  <p v-if="getPlan(plan.id)[0].interval == 'year'" class="wow">
                    BEST DEAL
                    SAVE 50%
                  </p>
                  <div class="currency">
                    {{
                    getCurrency(getPlan(plan.id)[0].currency)
                    }}{{
                    (getPlan(plan.id)[0].amount / 12).toString().split(".")[0]
                    }}<span v-if="getPlan(plan.id)[0].interval == 'year'">.</span><span v-if="getPlan(plan.id)[0].interval == 'year'" class="cents">{{
                      (Math.ceil((getPlan(plan.id)[0].amount / 12) * 20) / 20)
                      .toFixed(2)
                      .toString()
                      .split(".")[1]
                      }}
                    </span>
                    <p class="fullPrice">per month</p>
                  </div>
                  <div class="trail" v-if="plan.trial">{{ plan.trial }} DAY FREE TRIAL</div>
                </template>
              </div>
              <div class="continue-blk">
                <div class="bar"></div>
                <div
                  v-if="!typeOne"
                  class="neouButton"
                  @click="callParent(getPlan(plan.id), plan.name)"
                >continue</div>
                <p>
                  {{ getCurrency(getPlan(plan.id)[0].currency)}}{{ getPlan(plan.id)[0].amount }}
                  {{ getBilling(getPlan(plan.id)[0].interval) }}
                </p>
                <p>Cancel Anytime</p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="typeOne || selectedPlans"
          class="neouButton"
          :class="{ fluid: typeOne }"
          @click="callContinue"
        >continue</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanVariantA",
  data: () => ({
    fetchingPlans: true,
    selected: undefined,
    selectedPlans: "",
    title: ""
  }),
  methods: {
    callParent(selectedPlan, title) {
      this.$emit("onChange", selectedPlan, title);
    },
    callContinue() {
      this.$emit("onChange", this.selectedPlans, this.titles);
    },
    handlePlanSelected: function(selectedPlan, title) {
      // if (selectedPlan.length == 1) {
      //   selectedPlan = selectedPlan[0];
      // }
      selectedPlan[0]["name"] = title;
      this.$emit("selectedPlan", selectedPlan[0]);
      window.dataLayer.push({
        event: "select_plan",
        plan_id: selectedPlan[0].id
      });
      this.$emit("nextScreen");
    },
    getPlan: function(planToFind) {
      console.log(planToFind);
      let prices = [];
      this.plans.forEach(plan => {
        prices.push(...plan.plans);
      });
      return prices.filter(price => {
        return price.id == planToFind;
      });
    },
    getCurrency: function(currency) {
      if (currency == "usd") {
        return "$";
      }
      if (currency == "gbp") {
        return "£";
      }
      if (currency == "cad") {
        return "$";
      }
      if (currency == "aud") {
        return "$";
      }
    },
    getBilling: function(interval) {
      if (interval == "year") {
        return "billed annually";
      }
      if (interval == "month") {
        return "billed monthly";
      }
    },
    isPlanSelected: function() {
      if (
        typeof window.selectedPlan !== undefined &&
        window.preserveCheckout !== false
      ) {
        // this.handlePlanSelected(
        //   this.getPlan(window.selectedPlan.id),
        //   window.selectedPlan.name
        // );
      }
    }
  },
  props: {
    landscape: String,
    typeOne: Boolean,
    plans: Array,
    offer: Array
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cardSec {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.offerImageDesk {
  width: 150px;
  height: 213px;
  // display: none;
}
.offerImageMobile {
  width: 282px;
  display: none;
}
.offerBlk {
  cursor: pointer;
  padding: 10px;
}
.cardBlk {
  .planWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    margin: 0;
  }
  .cardWrap {
    width: 140px;
    height: 199px;
    margin: 15px 5px 15px;
    border-radius: 7.52px;
    border: solid 1px #ffffff;
    background: #f3f5f7;
    position: relative;
    z-index: 999;
    &.typeOne {
      height: 180px;
      border: solid 3px #ffffff;
      cursor: pointer;
      .wow {
        padding: 6px 7px;
      }
    }
    &.hasWow {
      border: 3px solid #30e1fe;
    }
    &.landscape {
      width: 293px;
      .wow {
        &:before {
          border-width: 8px 145px 0 145px;
        }
      }
    }
  }
  .currency {
    // height: 60px;
    color: #12161a;
    font-size: 32px;
    font-weight: 400;
    font-family: "prox", Helvetica;
    line-height: 30px;
    padding-top: 35px;
    text-align: center;
    letter-spacing: -0.1828571px;
    // margin-bottom: 12px;
  }
  .continue-blk {
    p {
      font-size: 10px;
      margin: 0;
      color: #12161a;
      text-align: center;
      font-weight: 400;
    }
  }
  .neouButton {
    font-size: 12px;
    width: 110px;
    height: 30px;
    margin: 0 auto;
    padding: 6px 7px;
    color: #12161a;
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 8px;
    &.fluid {
      width: 290px;
      height: 48px;
      padding: 15px;
      font-size: 15px;
    }
  }
  .bar {
    width: 109px;
    margin: 10px auto;
    border: solid 1px #dfdfdf;
  }
  .fullPrice {
    height: 18px;
    color: #717171;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin: 0;
  }
  .trail {
    height: 16px;
    color: #12161a;
    font-size: 11.5px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.2px;
  }
  .wow {
    background-color: #222a31;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 11px;
    line-height: 1.125em;
    text-transform: uppercase;
    font-weight: 400;
    padding: 6px 8px;
    color: #ffffff;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0px;
    text-align: center;
    &::before {
      display: block;
      content: "";
      width: 0;
      position: absolute;
      bottom: -8px;
      left: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 69px 0 69px;
      border-color: #222a31 transparent transparent transparent;
      line-height: 0px;
      _border-color: #222a31 #000000 #000000 #000000;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    }
  }
}
@media screen and (min-width: 768px) {
  .offerImageDesk {
    display: block;
  }
  .offerImageMobile {
    display: none;
  }
  // .cardSec {
  //   flex-direction: column;
  // }
}
</style>