<template>
  <div class="onboardingWrapper">
    <div class="onboardingHeader">
      <div class="onboardingHeaderWrapper">
        <!-- <md-icon class="backIcon">west</md-icon> -->
        <p class="onboardingHeading">My Fitness Profile</p>
      </div>
    </div>
    <div class="questionScreen" v-if="step == 1">
      <div class="questionHeader">
        <p class="questionHeading">Personalize your experience.</p>
        <p class="questionText">
          NEOU can recommend the best workout and programs to help you reach
          your fitness goals.
        </p>
      </div>
      <md-field :class="getValidationClass('firstName')">
        <label for="firstName">First Name</label>
        <md-input
          name="firstName"
          id="firstName"
          autocomplete="off"
          v-model="form.firstName"
        />
        <span class="md-error" v-if="!$v.form.firstName.required"
          >First name is required</span
        >
      </md-field>
      <md-field :class="getValidationClass('username')">
        <label for="username">Unique Username</label>
        <md-input
          name="username"
          id="username"
          autocomplete="off"
          v-model="form.username"
        />
        <span class="md-error" v-if="!$v.form.username.required"
          >Username must be longer than 3 characters is required</span
        >
        <span class="md-error forceShow" v-if="usernameError"
          >{{usernameError}}</span
        >
      </md-field>
      <md-field :class="getValidationClass('ageGroup')">
        <label for="ageGroup">Age Group</label>
        <md-select v-model="form.ageGroup" name="ageGroup" id="ageGroup">
          <md-option
            v-for="ageGroup in ageGroups"
            :value="ageGroup.id"
            :key="ageGroup.id"
            >{{ ageGroup.name }}</md-option
          >
        </md-select>
      </md-field>
      <md-field :class="getValidationClass('gender')" v-if="!hideGender">
        <label for="ageGroup">Gender</label>
        <md-select v-model="form.gender" name="gender" id="gender">
          <md-option v-for="gender in genders" :value="gender" :key="gender">
            {{ gender }}
          </md-option>
        </md-select>
      </md-field>
      <md-button
        type="submit"
        class="md-raised neouButton"
        @click.native="submitProfile"
        >Get Started</md-button
      >
      <a class="later" v-if="optionalOnboarding" @click="skipOnboarding"
        >Skip this step</a
      >
    </div>
    <div class="questionScreen" v-if="middleSteps">
      <p class="questionHeading">{{ activeQuestion.question }}</p>
      <ul class="answerList">
         <li
          class="answer"
          v-for="option in activeQuestion.options"
          :key="option._id"
          @click="
            submitOnboarding(
              activeQuestion.id,
              activeQuestion.question,
              option.text,
              option.title
            )
          "
        >
          <p class="title">{{ option.title }}</p>
          {{ option.text }}
        </li>
      </ul>
    </div>
    <div class="questionScreen lastQuestion" v-if="step == 5">
      <p class="questionHeading">{{ activeQuestion.question }}</p>
      <p class="goalText">I will work out {{ form.goal }} times a week</p>
      <ul class="answerList">
        <li
          class="goal"
          v-for="option in activeQuestion.options"
          :key="option._id"
          :class="{ active: form.goal == option.text }"
          @click="setGoal(option.text, option.subtitle)"
          @mouseover="setGoal(option.text, option.subtitle)"
        >
          {{ option.text }}
        </li>
      </ul>
      <p class="encouragement">{{ subtitle }}</p>
      <md-button
        type="button"
        @click.native="finish(activeQuestion.id, activeQuestion.question, form.goal)"
        class="md-raised neouButton"
        >Finish</md-button
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { MdField, MdIcon } from "vue-material/dist/components";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import "@/assets/tweaked-vue-material.min.css";

Vue.use(MdField);
Vue.use(MdIcon);

export default {
  name: "Onboarding",
  mixins: [validationMixin],
  data: () => ({
    step: 1,
    questions: {},
    ageGroups: [],
    genders: [],
    titleForSegment: "",
    usernameError: false,
    subtitle: "",
    hideGender: false,
    optionalOnboarding: false,
    form: {
      firstName: "",
      gender: "",
      ageGroup: "",
      goal: "_",
      fitnessProfileQA: []
    }
  }),
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(2)
      },
      username:{
        required,
        minLength: minLength(3)
      },
      gender: {
        required
      },
      ageGroup: {
        required
      }
    }
  },
  beforeMount() {
    // Get config options
    if ("neouHideGender" in window) {
      this.hideGender = true;
      this.form.gender = "Prefer not to say";
    }
    if ("neouOptionalOnboarding" in window) {
      this.optionalOnboarding = true;
    }
    axios
      .get("https://api.neoufitness.com/neou/v1/getFitnessProfileConfig", {
        headers: {
          Authorization: "JWT " + this.$cookie.get("token"),
          token: this.$cookie.get("getUserToken")
        }
      })
      .then(res => {
        this.questions = res.data.result;
      })
      .catch(err => {
        console.log(err);
      });
    // Get the age groups
    axios
      .get("https://api.neoufitness.com/neou/v1/ageGroup", {
        headers: {
          Authorization: "JWT " + this.$cookie.get("token"),
          token: this.$cookie.get("getUserToken")
        }
      })
      .then(res => {
        this.ageGroups.push(...res.data.result);
        this.genders.push(...res.data.gender);
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    submitOnboarding: function(id, question, answer, title = false) {
      if ( title ) {
        this.titleForSegment = title;
        answer = title;
      }
      let payload = {
        id: id,
        ques: question,
        ans: answer
      };
      this.form.fitnessProfileQA.push(payload);
      this.nextQuestion();
    },
    getValidationClass: function(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    submitProfile: function() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.nextQuestion();
      }
    },
    nextQuestion: function() {
      // Track onboarding
      window.analytics.track("Onboarding", {
        screen_index: this.step - 1
      });
      this.step = this.step + 1;
    },
    finish: function(id, question, answer) {
      let payload = {
        id: id,
        ques: question,
        ans: answer
      };
      this.form.fitnessProfileQA.push(payload);
      window.analytics.track("Onboarding", {
        screen_index: 5
      });
      delete this.form.username;
      axios
        .put("https://api.neoufitness.com/neou/v1/user/profile", this.form, {
          headers: {
            Authorization: "JWT " + this.$cookie.get("token"),
            token: this.$cookie.get("getUserToken")
          }
        })
        .then(res => {
          window.analytics.identify(this.$attrs.user, {
            user_group: res.data.segment,
            first_name: this.form.firstName,
            fitness_goal: this.titleForSegment,
            fitness_interest: this.form.fitnessProfileQA.filter(
              group => group.id == "5ef4ef0279a1a750d8e8cf7f"
            )[0].ans,
            fitness_level: this.form.fitnessProfileQA.filter(
              group => group.id == "5ef4ef0279a1a750d8e8cf71"
            )[0].ans,
            gender: this.form.gender,
            goal_target: this.form.goal
          });
          this.$emit("nextScreen");
        })
        .catch(err => {
          console.log(err);
        });
    },
    skipOnboarding: function() {
      this.$emit("nextScreen");
    },
    setGoal: function(goal, subtitle, id, question, answer) {
      this.form.goal = goal;
      this.subtitle = subtitle;
    }
  },
  computed: {
    activeQuestion: function() {
      return this.questions[this.step - 2];
    },
    middleSteps: function() {
      return this.step >= 2 && this.step <= 4;
    }
  },
  watch: {
    'form.username':function(newVal, oldVal){
      if( newVal.length > 2 ) {
        axios
          .put("https://api.neoufitness.com/neou/v1/user", {"displayName": newVal}, {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token"),
              token: this.$cookie.get("getUserToken")
            }
          })
          .then(res => {
            this.usernameError = false;
          })
          .catch(err => {
            this.usernameError = err.response.data.error;
          })
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.md-field .md-input,
.md-menu.md-select .md-input {
  color: #ffffff;
}
.md-select-value {
  color: #ffffff;
}
.md-field:after {
  border-bottom: 1px solid #35414a;
}
.md-field label {
  color: #8b949b;
  font-style: italic;
}
.md-list-item-text {
  padding: 10px 20px;
}
.md-invalid {
  &.md-field:after {
    border-bottom: 1px solid #ff1c58;
  }

  &.md-field label {
    color: #ff1c58;
  }

  .md-error {
    color: #ff1c58;
  }
}
.forceShow{
  opacity: 1 !important;
    transform: translateZ(0) !important;
    color:#ff1c58;
}
.md-menu-content {
  background-color: #1d2429;
  border-radius: 3px;
  color: #e6eaed;
  cursor: pointer;
}
.onboardingWrapper {
  width: 100%;
}
.onboardingHeaderWrapper,
.questionScreen {
  max-width: 400px;
  margin: 0px auto;
  padding: 0px 15px;
}
.questionHeading {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  margin: 20px auto;
  width: 80%;
}
.onboardingHeader {
  border-bottom: 1px solid #35414a;
}
.onboardingHeaderWrapper {
  text-align: center;
  position: relative;
}
.backIcon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.onboardingHeading {
  font-size: 16px;
  text-transform: uppercase;
}
.answerList {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.answer {
  background-color: #ffffff;
  color: #000000;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 10px 30px;
  margin-bottom: 20px;
  cursor: pointer;

  .title {
    font-weight: bold;
    margin: 0px;
    color: #000000;
  }
}
.later {
  text-align: center;
  margin: 20px 0px;
  display: block;
  color: #56fffe;
  font-size: 12px;
  cursor: pointer;
}
.lastQuestion {
  .answerList {
    display: flex;
    justify-content: space-between;
    .goal {
      background-color: #ffffff;
      color: #000000;
      border-radius: 100%;
      font-size: 14px;
      cursor: pointer;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      font-weight: bold;
      font-size: 18px;

      &.active {
        background-color: #56fffe;
      }
    }
  }
}
.goalText {
  color: #56fffe;
  font-size: 25px;
  text-align: center;
  max-width: 200px;
  margin: 50px auto 30px auto;
  line-height: 32px;
}
.encouragement {
  text-align: center;
  max-width: 300px;
  margin: 15px auto;
  font-style: italic;
}
</style>
