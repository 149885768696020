<template>
  <div class="checkoutWrapper">
    <img src="@/assets/neou-logo.png" class="neouLogo" />
    <!-- <div class="paymentWrapper">
      <div class="paymentReqBtnWrap">
        <span class="expressCheckout">Express Checkout</span>
      </div>
      <div v-if="!$browserDetect.isSafari" class="gPay">
        <img class="gPayImg" src="@/assets/google-pay-gpay-logo.png" />
      </div>
      <div v-if="$browserDetect.isSafari" class="gPay">
        <img class="gPayImg" src="@/assets/apple-pay.png" />
      </div>
    </div>
    <div class="orLine">
      <span>OR</span>
    </div>-->
    <div class="formCol" @click="clearError">
      <h2 class="formHeader">Enter your billing details below:</h2>
      <md-field>
        <label for="name">Name on Card</label>
        <md-input
          name="name"
          id="name"
          autocomplete="given-name"
          v-model="name"
        />
      </md-field>
      <card
        class="stripe-card"
        :class="{ complete }"
        stripe="pk_live_6H8kMwh8TAC2tAMnQI6TQzOg"
        :options="stripeOptions"
        @change="complete = $event.complete"
      />
    </div>
    <div class="buttonWrapper">
      <button
        class="pay-with-stripe"
        @click="pay"
        :disabled="!complete || submitting"
      >
        <md-progress-spinner md-mode="indeterminate" :md-diameter="20" :md-stroke="2" v-if="submitting"></md-progress-spinner>
        <span v-if="!submitting">
          <span v-if="this.selectedPlan.trial_period_days > 0"
            >Start your free trial now</span
          >
          <span v-else>Start your membership now</span>
        </span>
      </button>
    </div>
    <div v-if="formError !== false" class="formError">{{ formError }}</div>
    <div class="infoCol">
      <p class="headLine">
        <span class="plan">{{ this.selectedPlan.name }}</span>
      </p>
      <p class="details">
        <template v-if="this.selectedPlan.interval == 'year'">
          <span class="currency">
            {{ getCurrency(this.selectedPlan.currency) }}
          </span>
          <span
            class="dollars"
            v-if="
              parseInt(
                (this.selectedPlan.amount / 12)
                  .toFixed(2)
                  .toString()
                  .split('.')[1]
              ) > 95

              ||

              parseInt(
                (this.selectedPlan.amount / 12)
                  .toFixed(2)
                  .toString()
                  .split('.')[1]
              ) == 0
            "
          >
            {{
              parseInt(
                (this.selectedPlan.amount / 12).toString().split(".")[0]
              ) + 1
            }}
          </span>
          <span class="dollars" v-else>
            {{
              parseInt(
                (this.selectedPlan.amount / 12).toString().split(".")[0]
              )
            }}
          </span>
          <span class="dot">.</span><span class="cents">{{(Math.ceil((this.selectedPlan.amount / 12) * 20) / 20)
                .toFixed(2)
                .toString()
                .split(".")[1]}}</span>
          /month. {{ getCurrency(this.selectedPlan.currency)
          }}{{ this.selectedPlan.amount }}
          {{ getBilling(this.selectedPlan.interval) }}
          <br />
          <span
            v-if="this.selectedPlan.trial_period_days > 0"
            class="upper"
            >{{ this.selectedPlan.trial_period_days }}-day free
            trial.</span
          >
          Cancel anytime.
        </template>
        <template v-if="this.selectedPlan.interval_count > 1">
          <span class="currency">
            {{ getCurrency(this.selectedPlan.currency) }}
          </span>
          <span
            class="dollars"
            v-if="
              parseInt(
                (this.selectedPlan.amount / 6)
                  .toFixed(2)
                  .toString()
                  .split('.')[1]
              ) > 95
            "
          >
            {{
              parseInt(
                (this.selectedPlan.amount / 6).toString().split(".")[0]
              ) + 1
            }}
          </span>
          <span class="dollars" v-else>
            {{
              parseInt(
                (this.selectedPlan.amount / 6).toString().split(".")[0]
              )
            }}
          </span>
          <span class="dot">.</span>
          <span class="cents">
            {{
              (Math.ceil((this.selectedPlan.amount / 6) * 20) / 20)
                .toFixed(2)
                .toString()
                .split(".")[1]
            }}
          </span>
          /month. {{ getCurrency(this.selectedPlan.currency)
          }}{{ this.selectedPlan.amount }}
          Billed every 6 months
          <br />
          <span
            v-if="this.selectedPlan.trial_period_days > 0"
            class="upper"
            >{{ this.selectedPlan.trial_period_days }}-day free
            trial.</span
          >
          Cancel anytime.
        </template>
        <template v-if="this.selectedPlan.interval == 'month' && this.selectedPlan.interval_count == 1">
          {{ getCurrency(this.selectedPlan.currency)
          }}{{ this.selectedPlan.amount.toString().split(".")[0] }}.{{
            this.selectedPlan.amount.toString().split(".")[1]
          }}/month. {{ getBilling(this.selectedPlan.interval) }}
          <br />
          <span
            v-if="this.selectedPlan.trial_period_days > 0"
            class="upper tir"
            >{{ this.selectedPlan.trial_period_days }}-day free
            trial.</span
          >
          Cancel anytime.
        </template>
      </p>
    </div>
    <div class="couponItem" v-if="lineItemCouponApplied && coupon == 'WELLY'">
      <md-button class="removeButton" @click="removeCoupon">Remove</md-button>
      <p class="couponHint">Promo code applied</p>
      <p class="couponCode">WELLY</p>
      <p class="couponInfo">Get a free welly bottle with an annual membership.  Look out for an email from us at the end of your trial period. <a href="https://terms.neoufitness.com/welly-gwp" target="_blank" rel="noopener">Complete Details</a></p>
    </div>
    <div class="couponItem" v-if="lineItemCouponApplied && coupon.toUpperCase() == 'HEART'">
      <md-button class="removeButton" @click="removeCoupon">Remove</md-button>
      <p class="couponHint">Promo code applied</p>
      <p class="couponCode">{{coupon}}</p>
      <p class="couponInfo">Promo applied! $29.99/yr 30-day free trial with annual membership.</p>
    </div>
    <div class="couponForm" v-if="!lineItemCouponApplied">
      <md-field>
        <label for="coupon">Promo Code</label>
        <md-input
          name="coupon"
          id="coupon"
          autocomplete="off"
          v-model="coupon"
          class="couponField"
          @click="clearError"
        />
        <md-button class="removeButton" @click="applyCoupon"><span v-if='couponLoading'><md-progress-spinner md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner></span><span v-else>Apply</span></md-button>
        <span class="md-helper-text couponError" v-if="lineItemCouponError">{{lineItemCouponError}}</span>
      </md-field>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { MdField, MdProgress } from "vue-material/dist/components";
import axios from "axios";
import { Card, createPaymentMethod } from "vue-stripe-elements-plus";
import { parse } from "query-string";
import browserDetect from "vue-browser-detect-plugin";

Vue.use(browserDetect);
Vue.use(MdField);
Vue.use(MdProgress);

export default {
  name: "Checkout",
  data: () => ({
    submitting: false,
    msg: "Vue Browser Detect",
    name: null,
    complete: false,
    formError: false,
    allowedCodes:["WELLY", "HEART"],
    lineItemCouponApplied: false,
    lineItemCouponError: false,
    selectedPlan: {},
    coupon: "",
    shopCode: "",
    couponLoading: false,
    stripeOptions: {
      style: {
        base: {
          iconColor: "#35414A",
          color: "#8b949b",
          fontSize: "16px",

          '::placeholder': {
            color: '#8b949b',
          },
        }
      }
    },
    params: {},
    offer: window.planOffers.plans
  }),
  beforeMount: function() {
    window.analytics.track("Checkout Started");
    this.params = parse(window.location.search);
    this.selectedPlan = this.$attrs.selectedPlan;
  },
  components: { Card },
  methods: {
    getPlan: function(planToFind) {
      let prices = [];
      this.plans.forEach(plan => {
        prices.push(...plan.plans);
      });
      return prices.filter(price => {
        return price.id == planToFind;
      });
    },
    applyCoupon(){
      this.lineItemCouponError = false;
      if( this.allowedCodes.indexOf( this.coupon.toUpperCase() ) > -1 ) {
        if( this.$attrs.selectedPlan.interval == 'year' ) {
           if (this.coupon.toUpperCase() == "HEART") {
            this.couponLoading = true;
            axios
            .get(
              "https://api.neoufitness.com/neou/v1/subscription/plan?isMarketing=true",
              {
                headers: {
                  Authorization: "JWT " + this.$cookie.get("token"),
                  token: this.$cookie.get("getUserToken")
                }
              }
            )
            .then(res => {
             this.couponLoading = false;
             this.lineItemCouponApplied = true;
             this.plans = res.data.result.products;
             this.oldPlan = this.$attrs.selectedPlan.id;
             this.$attrs.selectedPlan = this.getPlan("price_1JPBtdDej2Rk1s0Kn1p47jQ0")[0];
             this.selectedPlan = this.getPlan("price_1JPBtdDej2Rk1s0Kn1p47jQ0")[0];
             this.selectedPlan.name = "Annual";
            })
            .catch(err => {
              console.log(err);
            });
          } else {
            this.lineItemCouponApplied = true;
          }
          
        } else {
          this.lineItemCouponError = "Coupon can only be applied to annual subscription"
        }
      } else {
        this.lineItemCouponError = "Coupon code invalid"
      }
    },
    removeCoupon(){
      this.lineItemCouponApplied = false;
      this.coupon = "";
      if ( this.oldPlan ) {
        this.$attrs.selectedPlan = this.getPlan(this.oldPlan)[0];
        this.selectedPlan = this.getPlan(this.oldPlan)[0];
      }
    },
    createSubscription( data ){
      axios
        .post(
          "https://api.neoufitness.com/neou/v1/user/subscription",
          {
            name: this.$attrs.email,
            payment: data.paymentMethod.id,
            plan: this.$attrs.selectedPlan.id
          },
          {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token"),
              token: this.$cookie.get("getUserToken")
            }
          }
        )
        .then(res => {
          // Succesfull Sub
          // Add in any UTM params
          let attr = {};
          if ("utm_source" in this.params) {
            attr["utm_source"] = this.params.utm_source;
          }
          if ("utm_medium" in this.params) {
            attr["utm_medium"] = this.params.utm_medium;
          }
          if ("utm_campaign" in this.params) {
            attr["utm_campaign"] = this.params.utm_campaign;
          }
          if ("utm_term" in this.params) {
            attr["utm_term"] = this.params.utm_term;
          }
          if ("utm_content" in this.params) {
            attr["utm_content"] = this.params.utm_content;
          }
          if (window.partner) {
            attr["b2b_partner"] = window.partner;
          }
          if ("n" in this.params) {
            attr["mktg_page_id"] = this.params.n;
          }
          if ("fl" in this.params) {
            attr["flow_type"] = this.params.fl;
          }
          if ("neouExternalId" in window ) {
            attr["external_id"] = this.params.ex_id;
          }
          if ("variant" in this.params) {
            attr["variant"] = this.params.variant;
          }
          if ("clickId" in this.params) {
            attr["pj_click_id"] = this.params.clickId;
          }
          // If friendbuy is inialized then we want to send over conversion info
          if ("friendbuyAPI" in window ) {
            friendbuyAPI.push([
              "track",
              "purchase",
              {
                id: res.data.subscribeId,
                amount: this.$attrs.selectedPlan.amount,
                currency: this.$attrs.selectedPlan.currency.toUpperCase(), 
                isNewCustomer: true, 
                customer: {
                  email: this.$attrs.email,
                  id: this.$attrs.user,
                } 
              },
            ]);
          }
          // Fire off the Web Attribution Event
          if (Object.keys(attr).length > 0) {
            window.analytics.track("Subscription Attribution", attr);
          }
          // Fire off a Finish Subscription Event
          window.dataLayer.push({
            event: "finish_subscription",
            plan_currency_code: this.$attrs.selectedPlan.currency,
            plan_price: this.$attrs.selectedPlan.amount,
            plan_name: this.$attrs.selectedPlan.id,
            transaction_id: res.data.subscribeId
          });
          // Annual or Monthly
          if (this.$attrs.selectedPlan.trial_period_days > 0) {
            window.dataLayer.push({
              event: "checkout_button_trial"
            });
          } else {
            window.dataLayer.push({
              event: "checkout_button_monthly"
            });
          }
          // Get the user info and send an updated subscription status
          axios
            .get("https://api.neoufitness.com/neou/v1/user/", {
              headers: {
                Authorization: "JWT " + this.$cookie.get("token"),
                token: this.$cookie.get("getUserToken")
              }
            })
            .then(res => {
              console.log( res.data );
              let attrs = {
                subscription_end_date: res.data.result.subscription.expiryDate,
                subscription_plan: res.data.result.subscription.productId,
                subscription_status: "active",
                subscription_start_date: new Date().toISOString(),
                subscription_platform: "web_browser",
                subscription_price_id: this.$attrs.selectedPlan.id
              };
              if( this.coupon.toUpperCase() == "WELLY" ) {
                attrs["welly_gwp_code"] = this.shopCode;
                attrs["welly_gwp_registrant"] = true;
              }
              window.analytics.identify(res.data.result.id, attrs);
              this.$emit("nextScreen");
            });
        })
        .catch(err => {
          this.formError = err.error.message;
        });
    },
    pay() {
      // Get a code if we need one
      if( this.coupon.toUpperCase() == "WELLY" ) {
        axios
        .get("https://us-central1-neou-redemptions.cloudfunctions.net/getWellyCode")
        .then(res => {
          console.log( res.data );
          this.shopCode = res.data.code;
        })
        .catch(err => {
          console.log(err);
        });
      }
      this.submitting = true;
      createPaymentMethod("card", {}).then(data => {
        if( data.error ) {
          console.log( data.error );
          this.formError = data.error.message;
          this.submitting = false;
          return;
        }
        if( this.$attrs.stripeId == "" ) {
          this.createSubscription( data );
        } else {
         axios
          .post(
            "https://app.neoufitness.com/update-payment",
            {
              payment_method_id: data.paymentMethod.id,
              customerId: this.$attrs.stripeId
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
              }
            })
          .then((response) => {
            if ( response.data.statusCode == 200 ) {
              this.createSubscription( data )
            } else {
              this.formError = "Card error";
              this.submitting = false;
            }
          })
          .catch(err => {
            this.formError = err.response.data.raw.message;
            this.submitting = false;
            return;
          });
        }
      });
    },
    getCurrency: function(currency) {
      if (currency == "usd") {
        return "$";
      }
      if (currency == "gbp") {
        return "£";
      }
      if (currency == "cad") {
        return "$";
      }
      if (currency == "aud") {
        return "$";
      }
    },
    getBilling: function(interval) {
      if (interval == "year") {
        return "Billed annually";
      }
      if (interval == "month") {
        return "Billed monthly";
      }
    },
    clearError: function() {
      this.formError = false;
      this.lineItemCouponError = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.md-progress-spinner {
  stroke: black;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  display: inline-flex;
}
.couponItem{
  border-radius:6px;
  background-color:#222a31;
  padding:15px;
  color:#12161A;
  font-size:12px;
  margin-top:20px;
}
.couponHint{
  color:#8B949B;
  margin:0px;
}
.couponCode{
  margin:0px;
  font-size:14px;
  color:#cdd3d8;
  font-weight:600;
}
.couponInfo{
  line-height:14px;
  font-style:italic;
  margin-bottom:0px;
  margin-top:10px;
  color:#cdd3d8;

  a{
    color:#cdd3d8;
    text-decoration:underline;
  }
}
.removeButton{
  float:right;
  background-color:#cdd3d8;
  border-radius:6px;
  color:#000000;
  text-transform:none;
  font-weight:400;
  font-size:12px;
  height:30px;
  min-width:68px;
}
.couponForm{
  margin-top:20px;
  .removeButton{
    margin-top:-5px;
  }
}
.couponField{
  text-transform:uppercase;
}
.couponError{
  color:red;
}
.paymentWrapper {
  text-align: center;
  padding: 16px;
  position: relative;
  border: 1px solid #35414a;
}
.paymentReqBtnWrap {
  top: -8px;
  position: absolute;
  justify-content: center;
  display: flex;
  color: white;
  width: 100%;
  left: 0;
}
.expressCheckout {
  color: #8b949b;
  font-family: "prox", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  background: #080b0d;
  padding: 0px 22px;
}
.gPay {
  background: white;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gPayImg {
  width: auto;
  background: white;
  height: 20px;
  z-index: 1;
  left: 0;
}
.orLine {
  overflow: hidden;
  padding: 0px;
  text-align: center;
  color: #35414a;
  line-height: 40px;
  width: 100%;
  &:before,
  &:after {
    background-color: #35414a;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 40%;
    color: #35414a;
  }
  &:before {
    right: 0.5em;
    margin-left: -25%;
  }
  &:after {
    left: 0.5em;
    margin-right: -25%;
  }
  span {
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    padding: 20px;
    color: #8c949b;
    font-weight: bold;
  }
}
.checkoutBox {
  margin: 0px;
}
.checkoutWrapper {
  color: #ffffff;
  max-width: 400px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 15px;
}
.neouLogo {
  width: 115px;
  margin: 0px auto 25px auto;
  display: block;
}
.infoCol {
  background: #222a31;
  border-radius: 6px;
  padding: 15px;
}
.headLine {
  margin: 0px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
}

.buttonWrapper {
  background: #080b0d;
  padding: 20px 0px;
  margin: 20px 0px 0px 0px;
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    top: 0px;
    bottom: 0px;
    right: -100px;
    left: -100px;
    background: #080b0d;
    border-top: 1px solid #35414a;
    position: absolute;
    display: block;
    z-index: 1;
  }
}
.details {
  color: #cdd3d8;
  padding: 0px 50px 0px 0px;
  font-size: 11px;
  line-height: 15px;
  margin: 10px 0px 0px 0px;
  text-align:left;
}
.formHeader {
  font-size: 14px;
  color: #56fffe;
  text-align: left;
  font-weight: 500;
}
.pay-with-stripe {
  width: 100%;
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none;
  line-height: 40px;
  font-size: 13px;
  background: linear-gradient(45deg, #22d6fe, #51fbfe);
  margin: 0px auto 0px auto;
  font-family: "prox", Helvetica, Arial, sans-serif;
  line-height: 20px;
  border-color: black;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: uppercase;
  box-sizing: border-box;
  font-weight: bold;
  display: block;
  position: relative;
  z-index: 2;
}
.md-field:after {
  border-bottom: 1px solid #35414a;
}
.stripe-card {
  padding-bottom: 5px;
  border-bottom: 1px solid #35414a;
}
.md-field label {
  color: #8b949b;
  font-size: italic;
}
.md-field .md-input {
  color: #ffffff;
}
h2 {
  text-align: center;
}
.formError {
  color: #ff1c58;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 10px;
}
.upper {
  text-transform: uppercase;
}
.currency,
.dollars,
.dot {
  float: left;
}
@media screen and (min-width: 550px) {
  .buttonWrapper {
    background: none;

    &::before {
      display: none;
    }
  }
}
</style>
