<template>
  <div class="redeemWrapper">
    <md-progress-spinner
      md-mode="indeterminate"
      :md-diameter="50"
      :md-stroke="3"
    ></md-progress-spinner>
    <div class="redeeming">Redeeming...</div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { MdProgress } from "vue-material/dist/components";
import "@/assets/tweaked-vue-material.min.css";
import { parse } from "query-string";

Vue.use(MdProgress);

export default {
  name: "Redeem",
  data: () => ({
    params: {}
  }),
  beforeMount: function() {
    this.params = parse(window.location.search);
  },
  mounted: function() {
    // if( this.$attrs.email.includes("@capitalone.com") ) {
    //   // COF users already have subscriptions
    //   let attr = {};
    //   if ("utm_source" in this.params) {
    //     attr["utm_source"] = this.params.utm_source;
    //   }
    //   if ("utm_medium" in this.params) {
    //     attr["utm_medium"] = this.params.utm_medium;
    //   }
    //   if ("utm_campaign" in this.params) {
    //     attr["utm_campaign"] = this.params.utm_campaign;
    //   }
    //   if ("utm_term" in this.params) {
    //     attr["utm_term"] = this.params.utm_term;
    //   }
    //   if ("utm_content" in this.params) {
    //     attr["utm_content"] = this.params.utm_content;
    //   }
    //   if (window.partner) {
    //     attr["b2b_partner"] = window.partner;
    //   }
    //   if ("n" in this.params) {
    //     attr["mktg_page_id"] = this.params.n;
    //   }
    //   if ("neouExternalId" in window ) {
    //     attr["external_id"] = window.neouExternalId;
    //   }
    //   // Fire off the Web Attribution Event
    //   if (Object.keys(attr).length > 0) {
    //     window.analytics.track("Web Attribution", attr);
    //   }
    //   // Identify
    //   window.analytics.identify(res.id, {
    //     subscription_end_date: res.data.B2B.expires_date,
    //     subscription_plan: res.data.B2B.product_identifier,
    //     subscription_status: "active",
    //     subscription_start_date: res.data.B2B.purchase_date,
    //     subscription_platform: "web_browser"
    //   });
    //   this.$emit("nextScreen");
    // }
    if (typeof this.$attrs.user !== "undefined") {
      // Build the subscriber URL
      // let redeemURL = "http://localhost:5001/neou-redemptions/us-central1/createSubscriber"
      let redeemURL = "https://redeem.neoufitness.com/createSubscriber";
      redeemURL = redeemURL + "?email=" + encodeURIComponent(this.$attrs.email);
      redeemURL = redeemURL + "&user=" + this.$attrs.user;
      redeemURL = redeemURL + "&duration=" + window.duration;
      if( "neouEndDate" in window ) {
        redeemURL = redeemURL + "&endDate=" + window.neouEndDate;
      }
      axios
        .get(redeemURL)
        .then(res => {
          axios.put(
            "https://api.neoufitness.com/neou/v1/user/subscription/" +
              this.$attrs.user,
            {},
            {
              headers: {
                Authorization: "JWT " + this.$cookie.get("token"),
                token: this.$cookie.get("getUserToken")
              }
            }
          );
          let attr = {};
          if ("utm_source" in this.params) {
            attr["utm_source"] = this.params.utm_source;
          }
          if ("utm_medium" in this.params) {
            attr["utm_medium"] = this.params.utm_medium;
          }
          if ("utm_campaign" in this.params) {
            attr["utm_campaign"] = this.params.utm_campaign;
          }
          if ("utm_term" in this.params) {
            attr["utm_term"] = this.params.utm_term;
          }
          if ("utm_content" in this.params) {
            attr["utm_content"] = this.params.utm_content;
          }
          if (window.partner) {
            attr["b2b_partner"] = window.partner;
          }
          if ("n" in this.params) {
            attr["mktg_page_id"] = this.params.n;
          }
          if ("neouExternalId" in window ) {
            attr["external_id"] = window.neouExternalId;
          }
          // Fire off the Web Attribution Event
          if (Object.keys(attr).length > 0) {
            window.analytics.track("Web Attribution", attr);
          }
          // Identify
          window.analytics.identify(res.id, {
            subscription_end_date: res.data.B2B.expires_date,
            subscription_plan: res.data.B2B.product_identifier,
            subscription_status: "active",
            subscription_start_date: res.data.B2B.purchase_date,
            subscription_platform: "web_browser"
          });
          this.$emit("nextScreen");
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.redeemWrapper {
  text-align: center;
  margin-top: 25vh;
}
.redeeming {
  font-size: 24px;
  font-weight: 500;
  margin: 40px 0px;
}
.md-progress-spinner {
  stroke: #56fffe;
}
</style>
