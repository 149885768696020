<template>
  <div class="loading" v-if="fetchingPlans === true">
      <md-progress-spinner
        md-mode="indeterminate"
        :md-diameter="50"
        :md-stroke="3"
      ></md-progress-spinner>
    </div>
  <div v-else class="checkoutWrapper">
    <img src="@/assets/neou-logo.png" class="neouLogo" />
    <p class="plansIntro">Access 2500+ classes live and on demand<br> from your phone, tablet, web, or TV!</p>
    <h1 class="planHeader">Select Your Plan</h1>
    <!-- <div class="plansDisplay">
      <div
          class="v-planWrapper"
          v-for="plan in offer"
          :key="plan.name"
          :class="getPlan(plan.id)[0].interval"
        >
        <md-radio class="md-primary radio" :value="plan.id" v-model="selectedPlan"><div v-html="plan.name"></div></md-radio>
      </div>
    </div> -->
    <div class="planInfo"
          v-for="plan in offer"
          :key="plan.name"
          :class="[getPlan(plan.id)[0].interval, {active: plan.id == selectedPlan}, {pulse: (lineItemCouponApplied && coupon.toUpperCase() =='HEART') || (lineItemCouponApplied && coupon.toUpperCase().indexOf('21') > -1) || lineItemCouponApplied && coupon.toUpperCase() =='NEOUS'|| lineItemCouponApplied && coupon.toUpperCase() =='IHEART' || lineItemCouponApplied && coupon.toUpperCase() =='BLACKFRIDAY'|| lineItemCouponApplied && coupon.toUpperCase() =='CYBERMONDAY' || lineItemCouponApplied && coupon.toUpperCase() =='NEWYOU22' || lineItemCouponApplied && coupon.toUpperCase() =='FRIENDS' || lineItemCouponApplied && coupon.toUpperCase() =='TRINET' || lineItemCouponApplied && coupon.toUpperCase() =='KIDS'}]"
          @click="updateSelectedPlan(plan.id)">
        <div class="planLeft" v-if="(lineItemCouponApplied && coupon.toUpperCase() =='BLACKFRIDAY'|| lineItemCouponApplied && coupon.toUpperCase() =='CYBERMONDAY') && getPlan(plan.id)[0].trial_period_days == 365">
          <div class="priceWrapper">
            <p class="price">${{getPlan(plan.id)[0].amount}}<span class="period">/<span class="interval" v-if="getPlan(plan.id)[0].interval_count > 1">{{getPlan(plan.id)[0].interval_count}}</span> {{getPlan(plan.id)[0].interval}}</span></p>
            <p class="perk" v-if="getPlan(plan.id)[0].trial_period_days > 0">1-year free trial</p>
          </div>
          <p class="fine">You will not be charged until after your 1-year free trial. $34.99 billed annually. Your membership will auto-renew at the same price. Cancel anytime.</p>
        </div>
        <div class="planLeft" v-else>
          <div class="priceWrapper" :class="{noOldPrice: plan.id == 'price_1KZHirDej2Rk1s0Kq58JvxiS' }">
            <p class="price"><span class="oldPrice" v-if="plan.id !== 'price_1KZHirDej2Rk1s0Kq58JvxiS'"><span v-if="plan.name == 'Annual'">$79.99</span><span v-else>$7.99</span><span class="period">/ {{getPlan(plan.id)[0].interval}}</span></span>${{getPlan(plan.id)[0].amount}}<span class="period">/<span class="interval" v-if="getPlan(plan.id)[0].interval_count > 1">{{getPlan(plan.id)[0].interval_count}}</span> {{getPlan(plan.id)[0].interval}}</span></p>
            <p class="perk" v-if="getPlan(plan.id)[0].trial_period_days > 0">{{getPlan(plan.id)[0].trial_period_days}}-day free trial</p>
          </div>
          <p class="fine" v-if="getPlan(plan.id)[0].trial_period_days > 0">You will not be charged until after your {{getPlan(plan.id)[0].trial_period_days}}-day free trial. ${{getPlan(plan.id)[0].amount}} {{getBilling(getPlan(plan.id)[0].interval)}}. <span v-if="lineItemCouponApplied && coupon.toUpperCase() =='IHEART'">Your membership will auto-renew at the same price.</span> Cancel anytime.</p>
          <p class="fine" v-else>You will be charged ${{getPlan(plan.id)[0].amount}} today and your membership will auto-renew at ${{getPlan(plan.id)[0].amount}} every month</p>
        </div>
    </div>
    <div class="couponItem" v-if="lineItemCouponApplied && coupon == 'WELLY'">
      <md-button class="removeButton" @click="removeCoupon">Remove</md-button>
      <p class="couponHint">Coupon code applied</p>
      <p class="couponCode">WELLY</p>
      <p class="couponInfo">Get a free welly bottle with an annual membership.  Look out for an email from us at the end of your trial period. <a href="#">Complete Details</a></p>
    </div>
    <div v-if="lineItemCouponApplied && coupon.toUpperCase() == 'HEART' || (lineItemCouponApplied && coupon.toUpperCase().indexOf('21') > -1) || (lineItemCouponApplied && coupon.toUpperCase() == 'NEOUS' || lineItemCouponApplied && coupon.toUpperCase() =='IHEART') || (lineItemCouponApplied && coupon.toUpperCase() =='BLACKFRIDAY')|| (lineItemCouponApplied && coupon.toUpperCase() =='CYBERMONDAY')|| (lineItemCouponApplied && coupon.toUpperCase() =='NEWYOU22')|| (lineItemCouponApplied && coupon.toUpperCase() =='FRIENDS') || (lineItemCouponApplied && coupon.toUpperCase() =='TRINET') || (lineItemCouponApplied && coupon.toUpperCase() =='KIDS')">
      <md-field>
        <label for="coupon">Promo Code</label>
        <md-input
          name="coupon"
          id="coupon"
          autocomplete="off"
          v-model="coupon"
          class="couponField"
          v-bind:class="{couponApplied: lineItemCouponApplied}"
        />
        <md-button class="removeButton" @click="removeCoupon">Remove</md-button>
      </md-field>
      <p class="inlineCouponInfo" v-if="coupon.toUpperCase() == 'HEART'">{{coupon}} applied. $29.99/yr 30-day free trial with annual membership.</p>
      <p class="inlineCouponInfo" v-else-if="coupon.toUpperCase() == 'NEOUS'">{{coupon}} applied. Enjoy a 14-day trial for annual subscriptions.</p>
      <p class="inlineCouponInfo" v-else-if="coupon.toUpperCase() == 'IHEART'">{{coupon}} applied. Enjoy your discounted memberhsip 30-day trial.</p>
      <p class="inlineCouponInfo" v-else-if="coupon.toUpperCase() == 'BLACKFRIDAY' || coupon.toUpperCase() == 'CYBERMONDAY'">{{coupon}} applied. Enjoy your FREE year!</p>
      <p class="inlineCouponInfo" v-else-if="coupon.toUpperCase() == 'NEWYOU22'">{{coupon}} applied.</p>
      <p class="inlineCouponInfo" v-else-if="coupon.toUpperCase() == 'FRIENDS'">{{coupon}} applied. Enjoy $10 off NEOU.</p>
      <p class="inlineCouponInfo" v-else-if="coupon.toUpperCase() == 'TRINET'">{{coupon}} applied. Enjoy your discounted membership and 30 day free trial.</p>
      <p class="inlineCouponInfo" v-else-if="coupon.toUpperCase() == 'KIDS'">{{coupon}} applied. Enjoy your discounted membership.</p>
      <p class="inlineCouponInfo" v-else>{{coupon}} applied. Enjoy 50% off and a 30-day trial for your annual subscription.</p>
    </div>
    <div class="couponForm" v-if="!lineItemCouponApplied">
      <md-field>
        <label for="coupon">Coupon Code</label>
        <md-input
          name="coupon"
          id="coupon"
          autocomplete="off"
          v-model="coupon"
          class="couponField"
          @keyup.enter.native="applyCoupon"
        />
        <md-button class="removeButton" @click="applyCoupon">Apply</md-button>
        <span class="md-helper-text couponError" v-if="lineItemCouponError">{{lineItemCouponError}}</span>
      </md-field>
    </div>
    <div v-if="payButtons" class="formHeader">
      <span>pay with express checkout</span>
    </div>
   <StripeElements
      :stripe-key="stripeKey"
      :instance-options="instanceOptions"
      :elements-options="elementsOptions"
      v-if="stripeReady && payButtons"
      #default="{ elements }"
      ref="elmsRequest"
    >
      <StripeElement
        type="paymentRequestButton"
        :elements="elements"
        :options="requestButtonOptions"
        ref="requestButton"
        @ready="updateSelectedPlan(selectedPlan)"
      />
    </StripeElements>
    <div class="formCol" @click="clearError">
      <h2 class="formHeader" :class="{express: payButtons}"><span :class="{orWrapper: payButtons}"><span v-if="payButtons">or </span>Enter your Card Information</span></h2>
      <md-field>
        <label for="name">Name on Card</label>
        <md-input
          name="name"
          id="name"
          autocomplete="given-name"
          v-model="name"
          @change.once="formModified"
        />
      </md-field>
      <StripeElements
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
        v-if="stripeReady"
        #default="{ elements }"
        ref="elms"
      >
      <div class="stripe-card">
        <StripeElement
          type="card"
          :elements="elements"
          :options="cardOptions"
          ref="card"
          @change="cardChange($event)"
        />
      </div>
    </StripeElements>
    </div>
    <div class="buttonWrapper">
      <button
        class="pay-with-stripe"
        @click="clickPay"
        :disabled="!complete || submitting"
        :class="{submitting: submitting}"
      >
        <span v-if="getPlan(this.selectedPlan)[0].trial_period_days > 0">Start your free trial now</span>
        <span v-else>Start your membership</span>
        <md-progress-spinner v-if="submitting" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
      </button>
    </div>
    <div v-if="cardError" class="formError">{{ cardError }}</div>
    <div class="poweredBy">Guaranteed SECURE &amp; SAFE checkout <img src="@/assets/pbs.png" class="poweredByImage"></div>
    <div class="finePrint">
      By clicking start your free trial, you are locking in this membership price for as long as you remain a NEOU member without cancelling.  You agreee that after your free trial, NEOU will continue your membership and charge the membership fee until you cancel.<br>You may cancel at anytime.
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { MdField, MdRadio } from "vue-material/dist/components";
import axios from "axios";
import { loadStripe } from '@stripe/stripe-js';
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";
import { parse } from "query-string";
import browserDetect from "vue-browser-detect-plugin";

Vue.use(browserDetect);
Vue.use(MdField);
Vue.use(MdRadio);

export default {
  name: "JanCheckout",
  data: () => ({
    showCard: true,
    submitting: false,
    name: null,
    complete: false,
    cardError: false,
    didTogglePlans: false,
    allowedCodes:[
      "WELLY",
      "HEART",
      "NEOUS",
      "IHEART",
      "BLACKFRIDAY",
      "CYBERMONDAY",
      "ANDREA21",
      "ANDREW21",
      "AMANDA21",
      "BLAKE21",
      "BOBH21",
      "BRIANNA21",
      "BRITNEY21",
      "CHRISTI21",
      "CAMERON21",
      "DANI21",
      "EBONNY21",
      "ELISSA21",
      "FORME21",
      "IVY21",
      "JOSEPH21",
      "KICKIT21",
      "SARAH21",
      "STAN21",
      "SYDNI21",
      "TARA21",
      "NEWYOU22",
      "FRIENDS",
      "TRINET",
      "KIDS",
      "BARRED22",
      "MOTIVA22",
      "ESSENTIALS22",
      "BREATHING22",
      "FITCHOREO22",
      "YOGASCULPT22",
      "FORT22",
      "AFROBEAT22",
      "HEALTHYHUSTLE22",
      "KATFOWLER22",
      "VXN22",
      "TRUFORMRUNNER22",
      "FITFOURTH22",
      "RUNCLUB22",
      "ROCKSOLIDPILATES22",
      "DESAFIOHEAT22",
      "FITANDLIT22",
      "E322",
      "STRIKE22",
      "RIIDE22",
      "IGNITE22",
      "BELLSUP22",
      "S2S22",
      "ALIGNFLOW22",
      "MOVE22",
      "BRAVEBODY22",
      "JANEDO22",
      "FORZAG22",
      "THORISDOTTIR22",
      "THEPROGRAM22",
      "JKFIT22",
      "BURN6022",
      "FITSPHERE22",
      "KIDS22",
      "CEASAR21"
    ],
    couponLookup:{
      "ANDREA21": "brand_adffitness_brand",
      "ANDREW21": "brand_limitless",
      "AMANDA21": "brand_adp_amanda",
      "BLAKE21": "brand_base",
      "BOBH21": "brand_bob_harper",
      "BRIANNA21": "brand_bodybybree",
      "BRITNEY21": "brand_move_britneybyfield",
      "CHRISTI21": "brand_go_christi",
      "CAMERON21": "brand_cardioburn_cameron",
      "DANI21": "brand_shred_daniirwin",
      "EBONNY21": "brand_funwithfit",
      "ELISSA21": "brand_gesticulate",
      "FORME21": "brand_formebarre",
      "IVY21": "brand_fullout_ivy",
      "JOSEPH21": "brand_push_josephd",
      "KICKIT21": "brand_kickitbyeliza",
      "SARAH21": "brand_sarahotey",
      "SYDNEY21": "brand_fullout_sydni",
      "STAN21": "brand_onefit",
      "TARA21": "brand_empwr",
      "BARRED22": "brand_barred",
      "MOTIVA22": "brand_motiva",
      "ESSENTIALS22": "brand_essentials",
      "BREATHING22": "brand_thebreathingclass",
      "FITCHOREO22": "brand_fitchoreo",
      "YOGASCULPT22": "brand_yogasculpt",
      "FORT22": "brand_thefort",
      "AFROBEAT22": "brand_afrobeat",
      "HEALTHYHUSTLE22": "brand_healthyhustle",
      "KATFOWLER22": "brand_katfowler_katfowler",
      "VXN22": "brand_vxn",
      "TRUFORMRUNNER22": "brand_trueform",
      "FITFOURTH22": "brand_melissaparisfitness_melissa",
      "RUNCLUB22": "brand_runclub_kenny",
      "ROCKSOLIDPILATES22": "brand_rocksolidpilates",
      "DESAFIOHEAT22": "brand_desafioheat",
      "FITANDLIT22": "brand_fitandlit_janeilmanson",
      "E322": "brand_elenamoffa",
      "STRIKE22": "brand_strike_G",
      "RIIDE22": "brand_riidetaylor",
      "IGNITE22": "brand_hancock",
      "BELLSUP22": "brand_bellsup_lacee",
      "S2S22": "brand_s2s",
      "ALIGNFLOW22": "brand_alignflow_kendra",
      "MOVE22": "brand_move_britneybyfield",
      "BRAVEBODY22": "brand_bravebodyproject",
      "JANEDO22": "brand_janedo",
      "FORZAG22": "brand_forzag",
      "THORISDOTTIR22": "brand_thorisdottir",
      "THEPROGRAM22": "brand_theprogram",
      "JKFIT22": "brand_jkfit",
      "BURN6022": "brand_burn60",
      "FITSPHERE22": "brand_fitspherebylivlo",
      "KIDS22": "brand_kids",
      "CEASAR21": "brand_thejourney_ceasarbarajas"
    },
    lineItemCouponApplied: false,
    lineItemCouponError: false,
    coupon: "",
    shopCode: "",
    oldPlan: "",
    oldMonthlyPlan: "",
    stripeKey: 'pk_live_6H8kMwh8TAC2tAMnQI6TQzOg',
    stripeReady: false,
    instanceOptions:{},
    elementsOptions:{
      fonts:[
        {
          family: 'prox',
          src: 'url(https://get.neoufitness.com/fonts/proximanova-regular-webfont.woff)',
          weight:'400'
        }
      ]
    },
    elements:{},
    cardOptions:{
      style: {
        base: {
          iconColor: "#35414A",
          color: "#8b949b",
          fontSize: "16px",
          fontFamily: '"prox", Helvetica',
          color:"#12161A",
          '::placeholder':{
            color:'#8B949B'
          }
        }
      }},
    requestButtonOptions:{},
    payButtons:false,
    params: {},
    offer: window.planOffers.plans,
    fetchingPlans: true,
    plans: {},
    selectedPlan: window.planOffers.plans[0].id
  }),
  beforeMount: function() {
    if( window.localStorage.getItem( 'neouPlans' ) == null ) {
      axios
        .get(
          "https://api.neoufitness.com/neou/v1/subscription/plan?isMarketing=true",
          {
            headers: {
              Authorization: "JWT " + this.$cookie.get("token"),
              token: this.$cookie.get("getUserToken")
            }
          }
        )
        .then(res => {
          console.log(res.data.result.products);
          this.plans = res.data.result.products;
          // Track View Plans
          window.analytics.track("View Plans", {
            products_displayed: this.offer.map(plan => plan.id)
          });
          this.fetchingPlans = false;
        })
        .catch(err => {
          console.log(err);
        });
      } else {
        console.log( 'got stored plans' );
        this.plans = JSON.parse(window.localStorage.getItem( 'neouPlans' ));
        // Track View Plans
        window.analytics.track("View Plans", {
          products_displayed: this.offer.map(plan => plan.id)
        });
        this.fetchingPlans = false;
      }
    window.dataLayer.push({
      event: "view_plans",
      products_displayed: "Annual"
    });
    this.params = parse(window.location.search);
    // Auto-apply coupon
    if( "neouCoupon" in window ){
      this.lineItemCouponApplied = true;
      this.coupon = window.neouCoupon;
      this.applyCoupon();
    }

    // Load stripe
    this.stripe = loadStripe(this.stripeKey)
    this.stripe.then((stripeInstance) => {
      this.stripeInstance = stripeInstance;
      // create the default payment request
      const paymentRequest = stripeInstance.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'NEOU Annual Membership',
          amount: 3999,
        },
        requestPayerEmail: true,
        requestPayerName: true,
        requestPayerPhone: true,
        hidePostalCode: true,
      })

      // Check that the payment request API is available
      paymentRequest.canMakePayment().then(result => {
        if (result) {
          this.payButtons = true;
          // Track it
          window.analytics.track("Pay Buttons", result);
          window.dataLayer.push({
            event: "pay_button_shown"
          });
          this.requestButtonOptions = { paymentRequest }

          this.stripeReady = true;

           // When submitted, get the payment method and pass it to pay method
          paymentRequest.on('paymentmethod', (ev) => {
            this.pay(ev.paymentMethod)
            ev.complete('success');
          });
        } else {
          this.stripeReady = true;
        }
      });      
    });
  },
  components: { StripeElements, StripeElement },
  watch: {
    selectedPlan: function(){
      if ( !this.didTogglePlans ) {
        window.dataLayer.push({
          event: "view_plans",
          products_displayed: "Month"
        });
        this.didTogglePlans = true;
      }
    }
  },
  methods: {
    cardChange(event){
      console.log(event);
      if(typeof event.error !== 'undefined'){
        this.cardError = event.error.message;
      } else {
        this.cardError = false;
      }
      if(event.complete){
        this.complete = true;
      }
    },
    updateSelectedPlan(planId){
      if( this.payButtons ){
        this.selectedPlan = planId;
        this.$refs.requestButton.options.paymentRequest.update({
          total: {
            label: 'NEOU ' + (this.getPlan(this.selectedPlan).interval = "year") ? 'Annual' : 'Monthly' + ' Membership',
            amount: Math.ceil(this.getPlan(this.selectedPlan)[0].amount * 100),
          }
        });
      } else {
        this.selectedPlan = planId;
      }
    },
    applyCoupon(){
      this.lineItemCouponError = false;
      if( this.allowedCodes.indexOf( this.coupon.toUpperCase() ) > -1 ) {
        this.lineItemCouponApplied = true;
        if (this.coupon.toUpperCase() == "HEART") {
          this.oldPlan = this.offer[0].id;
          this.offer[0].id = "price_1JPBtdDej2Rk1s0Kn1p47jQ0";
          this.selectedPlan = "price_1JPBtdDej2Rk1s0Kn1p47jQ0";
        }
        if( this.coupon.toUpperCase() == "IHEART" ) {
          // Brand Coupons
          this.oldPlan = this.offer[0].id;
          this.oldMonthlyPlan = this.offer[1].id;
          // Set annaul plan
          this.offer[0].id = "price_1JfUFEDej2Rk1s0KXofEjggk";
          this.offer[1].id = "price_1JfUFxDej2Rk1s0KlPMQNG4C";
          this.selectedPlan = "price_1JfUFEDej2Rk1s0KXofEjggk";
        }
        if( this.coupon.toUpperCase().indexOf("21") > -1 ) {
          // Brand Coupons
          this.oldPlan = this.offer[0].id;
          this.oldMonthlyPlan = this.offer[1].id;
          // Set annaul plan
          this.offer[0].id = "price_1KZHkVDej2Rk1s0Kaxmgm3Ao";
          this.offer[1].id = "price_1KYEzVDej2Rk1s0KntJDOFVw";
          this.selectedPlan = "price_1KZHkVDej2Rk1s0Kaxmgm3Ao";
        }
        if( this.coupon.toUpperCase() == "NEOUS" ){
          this.oldPlan = this.offer[0].id;
          this.offer[0].id = "price_1JXS20Dej2Rk1s0KFCfciXom";
          this.selectedPlan = "price_1JXS20Dej2Rk1s0KFCfciXom";
        }
        if (this.coupon.toUpperCase() == "BLACKFRIDAY" || this.coupon.toUpperCase() == "CYBERMONDAY") {
          this.oldPlan = this.offer[0].id;
          this.offer[0].id = "price_1JxbtFDej2Rk1s0KhrWCwH1u";
          this.selectedPlan = "price_1JxbtFDej2Rk1s0KhrWCwH1u";
        }
        // New Years
        if( this.coupon.toUpperCase() == "NEWYOU22" ) {
          // Brand Coupons
          this.oldPlan = this.offer[0].id;
          this.oldMonthlyPlan = this.offer[1].id;
          // Set annaul plan
          this.offer[0].id = "price_1KYEy2Dej2Rk1s0KK9PFPtby";
          this.offer[1].id = "price_1KYEzVDej2Rk1s0KntJDOFVw";
          this.selectedPlan = "price_1KYEy2Dej2Rk1s0KK9PFPtby";
        }
        if( this.coupon.toUpperCase() == "FRIENDS" ){
          this.oldPlan = this.offer[0].id;
          this.offer[0].id = "price_1KR24KDej2Rk1s0KavyIRjn4";
          this.selectedPlan = "price_1KR24KDej2Rk1s0KavyIRjn4";
        }
        if( this.coupon.toUpperCase() == "TRINET" ){
          // Brand Coupons
          this.oldPlan = this.offer[0].id;
          this.oldMonthlyPlan = this.offer[1].id;
          // Set annaul plan
          this.offer[0].id = "price_1KW3ltDej2Rk1s0KHePgWBJj";
          this.offer[1].id = "price_1KW3rhDej2Rk1s0KKRelS7A5";
          this.selectedPlan = "price_1KW3ltDej2Rk1s0KHePgWBJj";
        }
        if( this.coupon.toUpperCase() == "KIDS" ){
          // Brand Coupons
          this.oldPlan = this.offer[0].id;
          // Set annaul plan
          this.offer[0].id = "price_1KYHhFDej2Rk1s0KRthtqy0n";
          this.selectedPlan = "price_1KYHhFDej2Rk1s0KRthtqy0n";
        }
        // Update the selectedplan for paybuttons
        this.updateSelectedPlan(this.selectedPlan);
      } else {
        this.lineItemCouponError = "Coupon code invalid"
      }
    },
    removeCoupon(){
      // Only for JAN
      this.showCard = false;
      this.lineItemCouponApplied = false;
      this.coupon = "";
      window.neouCoupon = false;
      setTimeout(() => {
        this.$emit("revertCheckout");
      },1000)
      this.lineItemCouponApplied = false;
      this.coupon = "";
      if ( this.oldPlan ) {
        this.offer[0].id = this.oldPlan;
        this.selectedPlan = this.oldPlan;
        // Update the selectedplan for paybuttons
        this.updateSelectedPlan(this.selectedPlan);
      }
      if ( this.oldMonthlyPlan ) {
        this.offer[1].id = this.oldMonthlyPlan;
      }
    },
    abbreviate( string ){
      if (string == "month" ) {
        return "mo";
      } else {
        return string;
      }
    },
    formModified() {
      window.analytics.track("Checkout Started");
      window.dataLayer.push({
        event: "checkout_started"
      });
    },
    clickPay(){
      // Submitting through the form
      this.$refs.elms.instance.createPaymentMethod({
          type:'card',
          card: this.$refs.card.stripeElement,
          billing_details: {
            name: this.name
          }
        }).then(result => {
          window.analytics.track("Pay Button Used");
          this.pay(result.paymentMethod);
       }).catch(err => {
        console.log(err);
      });
    },
    pay(paymentMethod) {
      console.log(paymentMethod);
      this.handlePlanSelected(this.getPlan(this.selectedPlan), this.getPlan(this.selectedPlan)[0].name);
      this.submitting = true;
        axios
          .post(
            "https://api.neoufitness.com/neou/v1/user/subscription",
            {
              name: this.email,
              payment: paymentMethod.id,
              plan: this.selectedPlan
            },
            {
              headers: {
                Authorization: "JWT " + this.$cookie.get("token"),
                token: this.$cookie.get("getUserToken")
              }
            }
          )
          .then(res => {
            // Succesfull Sub
            // Add in any UTM params
            let attr = {};
            if( this.coupon.indexOf('21') > -1 ) {
              attr["utm_source"] = "brand";
              attr["utm_medium"] = "brand";
              attr["utm_campaign"] = this.couponLookup[this.coupon.toUpperCase()];
            } else {
               if ("utm_source" in this.params) {
                attr["utm_source"] = this.params.utm_source;
              }
              if ("utm_medium" in this.params) {
                attr["utm_medium"] = this.params.utm_medium;
              }
              if ("utm_campaign" in this.params) {
                attr["utm_campaign"] = this.params.utm_campaign;
              }
            }
            if ("utm_term" in this.params) {
              attr["utm_term"] = this.params.utm_term;
            }
            if ("utm_content" in this.params) {
              attr["utm_content"] = this.params.utm_content;
            }
            if (window.partner) {
              attr["b2b_partner"] = window.partner;
            }
            if ("n" in this.params) {
              attr["mktg_page_id"] = this.params.n;
            }
            if ("fl" in this.params) {
              attr["flow_type"] = this.params.fl;
            }
            if ("clickId" in this.params) {
              attr["pj_click_id"] = this.params.clickId;
            }
            attr["price_id"] = this.selectedPlan;
            // Fire off the Web Attribution Event
            if (Object.keys(attr).length > 0) {
              window.analytics.track("Subscription Attribution", attr);
            }
            // Data for finish subscription event
            window.dataLayer.push({
              plan_currency_code: this.getPlan(this.selectedPlan)[0].currency,
              plan_price: this.getPlan(this.selectedPlan)[0].amount,
              plan_name: this.getPlan(this.selectedPlan)[0].id,
              transaction_id: res.data.subscribeId,
              transactionId: res.data.subscribeId,
              transactionTotal: this.getPlan(this.selectedPlan)[0].amount,
              transactionProducts: [
                {
                  name:this.getPlan(this.selectedPlan)[0].nickname,
                  sku:this.selectedPlan,
                  price:this.getPlan(this.selectedPlan)[0].amount,
                  quantity: 1
                }
              ]
            });
            // Fire off a Finish Subscription Event
            window.dataLayer.push({
              event: "finish_subscription",
            });
            // Annual or Monthly
            if (this.getPlan(this.selectedPlan)[0].trial_period_days > 0) {
              window.dataLayer.push({
                event: "checkout_button_trial"
              });
            } else {
              window.dataLayer.push({
                event: "checkout_button_monthly"
              });
            }
            // Get the user info and send an updated subscription status
            axios
              .get("https://api.neoufitness.com/neou/v1/user/", {
                headers: {
                  Authorization: "JWT " + this.$cookie.get("token"),
                  token: this.$cookie.get("getUserToken")
                }
              })
              .then(res => {
                console.log( res );
                let attrs = {
                  subscription_end_date: res.data.result.subscription.expiryDate,
                  subscription_plan: res.data.result.subscription.productId,
                  subscription_status: "active",
                  subscription_start_date: new Date().toISOString(),
                  subscription_platform: "web_browser",
                  subscription_price_id: this.$attrs.selectedPlan.id
                }
                if( this.coupon.toUpperCase() == "WELLY" ) {
                  attrs["welly_gwp_code"] = this.shopCode;
                  attrs["welly_gwp_registrant"] = true;
                }
                window.analytics.identify(res.id, attrs);
              });
            this.$emit("nextScreen");
          }) // Sub Then
          .catch(err => {
            this.formError = err.error.message;
            console.error(err.error.message);
          }); // Sub Catch
    },
    handlePlanSelected: function(selectedPlan, title) {
      // if (selectedPlan.length == 1) {
      //   selectedPlan = selectedPlan[0];
      // }
      selectedPlan[0]["name"] = title;
      this.$emit("selectedPlan", selectedPlan[0]);
      window.dataLayer.push({
        event: "select_plan",
        plan_id: selectedPlan[0].id
      });
      // Track Plan Selected
      window.analytics.track("Select Plan", {
        products_displayed: this.offer.map(plan => plan.id),
        selected_product: selectedPlan[0].id
      });
      // Annual or Monthly
      if (selectedPlan[0].trial_period_days > 0) {
        window.analytics.track("Select Plan Trial");
        window.dataLayer.push({
          event: "select_plan_trial"
        });
      } else {
        window.analytics.track("Select Plan Monthly");
        window.dataLayer.push({
          event: "select_plan_monthly"
        });
      }
    },
    getCurrency: function(currency) {
      if (currency == "usd") {
        return "$";
      }
      if (currency == "gbp") {
        return "Â£";
      }
      if (currency == "cad") {
        return "$";
      }
      if (currency == "aud") {
        return "$";
      }
    },
    getBilling: function(interval) {
      if (interval == "year") {
        return "billed annually";
      }
      if (interval == "month") {
        return "billed monthly";
      }
    },
    clearError: function() {
      this.formError = false;
    },
    getPlan: function(planToFind) {
      let prices = [];
      this.plans.forEach(plan => {
        prices.push(...plan.plans);
      });
      return prices.filter(price => {
        return price.id == planToFind;
      });
    }
  }
};
</script>
<style lang="scss">
.radio{
  .md-radio-container{
    border-color:#000000 !important;
    width: 15px;
    min-width: 15px;
    height: 15px;
  }

  label{
    font-size:11px;
    font-weight:600;
    padding-left:6px;
  }
}

.md-radio{
  margin-right:0px;
  .md-radio-label{
    padding-left:3px;
  }
}

.md-radio{
  &.md-checked{
    .md-radio-container{
      &:after{
        background-color:#000000;
      }
    }
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.neouLogo {
  width: 160px;
  margin: 0px auto;
  display: block;
  filter:invert(1);
}
.plansIntro{
  margin-top:10px;
  margin-bottom:20px;
  color:#505B63;
  text-align:center;
}
.md-progress-spinner {
  stroke: black;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  display: inline-flex;
}
.planHeader{
  color:#000000;
  font-size:13px;
  margin:0px 0px 15px 0px;
  text-align:center;
  font-weight:600;
  letter-spacing:0.54px;
  text-transform:uppercase;
}
.plansDisplay{
  color:#000000;
  display:flex;
  margin-bottom:15px;
  justify-content:space-around;
}
.couponItem{
  border:1px solid #CDD3D8;
  padding:10px 20px;
  color:#12161A;
  font-size:12px;
  margin-top:20px;
}
.couponHint{
  color:#8B949B;
  margin:0px;
}
.couponCode{
  margin:0px;
  font-size:14px;
  color:#1c2024;
  font-weight:500;
}
.couponInfo{
  line-height:14px;
  font-style:italic;
  margin-bottom:0px;
  margin-top:10px;
  color:#293138;

  a{
    color:#293138;
    text-decoration:underline;
  }
}
.removeButton{
  float:right;
  background-color:#35414a;
  border-radius:6px;
  color:#ffffff;
  text-transform:none;
  font-weight:400;
  font-size:12px;
  height:30px;
  min-width:68px;
  margin-top:-5px;
}
.couponField{
  text-transform:uppercase;
}
.couponError{
  color:red;
}
.planInfo{
  border:1px solid #CDD3D8;
  border-radius:6px;
}
.planInfo{
  text-align:center;
  cursor:pointer;
  display:flex;
  justify-content:space-between;
  padding:15px 20px 20px 20px;
  margin-bottom:15px;

  .price{
    color:#12161A;
    font-size:30px;
    font-weight:400;
    line-height:23px;
    vertical-align:top;
    margin:0px 0px 0px 0px;
  }
  .period{
    font-size:18px;
    display:inline-block;
    margin-left:5px;
  }
  .details{
    font-size:12px;
    line-height:16px;
    color:#222A31;
    margin:0px;
  }
  .red{
    color:#FF1C58;
    font-weight:500;
    text-transform:uppercase;
  }
  .boldCopy{
    font-weight:600;
    margin:4px 0px;
    font-size:10px;
  }
  .originalAmount{
    text-decoration:line-through;
  }
  .rate{
    font-style:italic;
  }
  .subtitle{
    background-color:#F3F3F3;
    color:#12161A;
    text-transform:uppercase;
    font-weight:600;
    font-size:12px;
    line-height:14px;
    display:block;
    text-align:center;
    padding:6px 0px;
    margin:0px;
  }
  &.year{
    .subtitle{
      background-color:rgba(255,229,68,0.35);
    }
  }
  .fine{
    color:#505B63;
    font-size:11px;
    line-height:16px;
    text-align:left;
    margin:0px;
  }
  &.active{
    border:3px solid #2ADCFE;
    
    .planLeft{
      margin:-2px;
    }

  }
}
.formHeader{
  text-transform:uppercase;
  font-weight:600;
  color:#000000;
  text-align:center;
  font-size:13px;
  margin-top:20px;
  margin-bottom:10px;

  &.express{
    position:relative;
    margin-bottom:0px;

    &::before{
      content:'';
      background:#ced6dd;
      height:1px;
      width:100%;
      z-index:0;
      left:0px;
      top:calc(50% - 0.5px);
      display:block;
      position:absolute;
    }
    .orWrapper{
      background:#ffffff;
      display:inline-block;
      padding:0px 5px;
      z-index:1;
      position:relative;
    }
  }
}
.md-field label {
  color: #8B949B;
  font-style: initial;
}
.poweredBy{
  margin-top:30px;
  color:#8B949B;
  font-size:10px;
  text-align:center;
}
.poweredByImage{
  display:inline-block;
  height:20px;
  opacity:0.6
}
.paymentWrapper {
  text-align: center;
  padding: 16px;
  position: relative;
  border: 1px solid #35414a;
}
.paymentReqBtnWrap {
  top: -8px;
  position: absolute;
  justify-content: center;
  display: flex;
  color: white;
  width: 100%;
  left: 0;
}
.expressCheckout {
  color: #8b949b;
  font-family: "prox", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  background: #080b0d;
  padding: 0px 22px;
}
.gPay {
  background: white;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gPayImg {
  width: auto;
  background: white;
  height: 20px;
  z-index: 1;
  left: 0;
}
.orLine {
  overflow: hidden;
  padding: 0px;
  text-align: center;
  color: #35414a;
  line-height: 40px;
  width: 100%;
  &:before,
  &:after {
    background-color: #35414a;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 40%;
    color: #35414a;
  }
  &:before {
    right: 0.5em;
    margin-left: -25%;
  }
  &:after {
    left: 0.5em;
    margin-right: -25%;
  }
  span {
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    padding: 20px;
    color: #8c949b;
    font-weight: bold;
  }
}
.couponApplied{
  cursor:default;
  pointer-events:none;
}
.checkoutBox {
  margin: 0px;
}
.checkoutWrapper {
  color: #ffffff;
  max-width: 400px;
  width: 100%;
  margin: 0px auto;
  padding: 40px 15px 40px;
}
.buttonWrapper {
  padding: 20px 0px 0px;
  margin: 20px 0px 0px 0px;
  position: relative;
  z-index: 2;
}
.pay-with-stripe {
  width: 100%;
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none;
  line-height: 40px;
  font-size: 13px;
  background: linear-gradient(45deg, #22d6fe, #51fbfe);
  margin: 0px auto 0px auto;
  font-family: "prox", Helvetica, Arial, sans-serif;
  line-height: 20px;
  border-color: black;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: uppercase;
  box-sizing: border-box;
  font-weight: bold;
  display: block;
  position: relative;
  z-index: 2;
}
.md-field:after {
  border-bottom: 1px solid #35414a;
}
.stripe-card {
  padding-bottom: 5px;
  border-bottom: 1px solid #35414a;
}

.md-field .md-input {
  color: #12161A;
}
.priceWrapper{
  display:flex;
  justify-content: space-between;
  align-items:middle;
  margin-bottom:10px;

  &.noOldPrice{
    margin-bottom:-10px;
    margin-top:5px;
  }
}
.perk{
  background-color:#12161A;
  padding:2px 6px;
  text-transform:uppercase;
  font-weight:bold;
  font-size:12px;
  height:22px;
  line-height:22px;
}
.inlineCouponInfo{
  font-size: 11px;
  line-height: 14px;
  text-align: left;
  margin: -18px 0px 0px 0px;
  color: rgb(140, 148, 155);
}

.oldPrice{
  font-size:16px;
  display:block;
  text-align:center;
  margin-bottom:5px;
  position:relative;
  color:#8B949B;

  &::before{
    content:'';
    height:1px;
    display:block;
    position:absolute;
    background:#FF1C58;
    left:20px;
    right:20px;
    top:50%;
  }

  .period{
    font-size:14px;
  }
}
@media screen and ( min-width: 500px ) {
  h2 {
    text-align: center;
  }
  .formError {
    color: #ff1c58;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
  }
  .upper {
    text-transform: uppercase;
  }
  .currency,
  .dollars,
  .dot {
    float: left;
  }
}
@media screen and (min-width: 550px) {
  .buttonWrapper {
    background: none;

    &::before {
      display: none;
    }
  }
  .radio{
    label{
      font-size:15px;
    }
  }

  .md-radio{
    margin-right:16px;
  }

  .planInfo{
    .price{
      text-align:left;
    }
    .details{
      text-align:left;
    }
  }
}

.finePrint{
  color:#8B949B;
  font-size:11px;
  text-align:center;
  margin-top:15px;
}

@media screen and (max-width:380px) {
  .planInfo{
    padding:10px;
    .price{
      font-size:28px;
    }

    .perk{
      font-size:11px;
    }

    .period{
      font-size:14px;
    }
  }
}

.planInfo{
  box-shadow: 0 0 0 0 rgba(42,220,254, 1);
  transform: scale(1);

  &.pulse{
    &.active{
      &.year{
        animation: pulse 1.25s;        
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(42,220,254, 0.7);
    background:rgba(42, 219, 254, 0.1);
  }

  20%{
    transform:scale(0.95);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(42,220,254, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(42,220,254, 0);
  }
}
</style>