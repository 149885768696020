<template>
  <div v-if="plans">
    <img src="@/assets/neou-logo.png" class="neouLogo" />
    <p class="logoSub">Access 2500+ classes live and on demand<br> from your phone, tablet, web, or TV!</p>
    <h2 class="select">Select a Plan</h2>
    <!-- <div class="ctaPlans">
      <div
        class="ctaPlan js-viewplans"
        data-ga-action="click"
        data-ga-category="CTA"
        data-ga-label="Plans CTA"
        v-for="plan in offer"
        :key="plan.name"
        @click="handlePlanSelected(getPlan(plan.id), plan.name)"
      >
        <div class="planColumn">
          <h3 class="planName">{{ plan.name }}</h3>
          <p class="planExtra" v-if="plan.trial > 0">
            <span class="lower">with</span>
            {{ plan.trial }}-day free trial
          </p>
        </div>
        <div class="planColumn">
          <p class="planPrice">
            <span class="dollarSign a">{{
              getCurrency(getPlan(plan.id)[0].currency)
            }}</span
            >{{
              getPlan(plan.id)[0]
                .amount.toString()
                .split(".")[0]
            }}<span class="cents">99</span>
          </p>
          <p class="planPeriod">
            - {{ getBilling(getPlan(plan.id)[0].interval) }} -
          </p>
        </div>
      </div>
    </div>-->
    <div class="loading" v-if="fetchingPlans">
      <md-progress-spinner
        md-mode="indeterminate"
        :md-diameter="50"
        :md-stroke="3"
      ></md-progress-spinner>
    </div>
    <div v-else class="plansDisplay">
      <!-- <TabPlan @onChange="childCallBack" landscape :offer="offer" /> -->
      <PlanVariantAsec
        v-if="variant === 'PLAN-L-vA'"
        @onChange="childCallBack"
        :plans="plans"
        :offer="offer"
      />
      <PlanVariantBsec
        v-if="variant === 'PLAN-L-vB'"
        @onChange="childCallBack"
        :plans="plans"
        :offer="offer"
      />
      <div
        class="planWrapper"
        v-if="!variant"
        v-for="plan in offer"
        :key="plan.name"
        :class="[
          {
            primary: plan.primary,
            single: offer.length < 0,
            hasWow: plan.wow !== false,
            planWrapperNo:
              getPlan(plan.id)[0].interval == 'month' &&
              plan.image &&
              (getPlan(plan.id)[0].offerImage['_180wide'] || typeof plan.image === 'string'),
            planWrapperNoYear:
              getPlan(plan.id)[0].interval == 'year' &&
              plan.image &&
              (getPlan(plan.id)[0].offerImage['_180wide'] || typeof plan.image === 'string')
          },
          getPlan(plan.id)[0].interval
        ]"
        @click="handlePlanSelected(getPlan(plan.id), plan.name)"
      >
        <div class="planHeader">
          <div class="priceGroup">
            <template v-if="getPlan(plan.id)[0].interval == 'year'">
              <div
                :class="[
                  {
                    offerBlk:
                      !plan.image || !getPlan(plan.id)[0].offerImage['_180wide']
                  }
                ]"
                v-if="
                  plan.image === true &&
                    getPlan(plan.id)[0].offerImage['_180wide']
                "
                class="offerBlk"
              >
                <img
                  class="offerImageDesk"
                  :src="getPlan(plan.id)[0].offerImage['_180wide']"
                />
                <img
                  class="offerImageMobile"
                  :src="getPlan(plan.id)[0].offerImage['_282wide']"
                  alt="loading..."
                />
              </div>

              <div v-if="typeof plan.image === 'string'" class="offerBlk">
                <img
                  class="offerImageDesk"
                  :src="plan.image + '.png'"
                >
                <img
                  class="offerImageMobile"
                  :src="plan.image + '-mobile.png'"
                >
              </div>
              <div
                v-else-if="
                  !plan.image || !getPlan(plan.id)[0].offerImage['_180wide']
                "
                class="cardWrap"
              >
                <span class="currency">{{
                  getCurrency(getPlan(plan.id)[0].currency)
                }}</span>
                <span
                  class="dollars"
                  v-if="
                    parseInt(
                      (getPlan(plan.id)[0].amount / 12)
                        .toFixed(2)
                        .toString()
                        .split('.')[1]
                    ) > 95

                    ||

                    parseInt(
                (getPlan(plan.id)[0].amount / 12)
                        .toFixed(2)
                        .toString()
                        .split('.')[1]
                    ) == 0
                  "
                >
                  {{
                    parseInt(
                      (getPlan(plan.id)[0].amount / 12).toString().split(".")[0]
                    ) + 1
                  }}
                </span>
                <span class="dollars" v-else>
                  {{
                    (getPlan(plan.id)[0].amount / 12).toString().split(".")[0]
                  }}
                </span>
                <div class="centsWrapper">
                  <span class="cents">
                    {{
                      (Math.ceil((getPlan(plan.id)[0].amount / 12) * 20) / 20)
                        .toFixed(2)
                        .toString()
                        .split(".")[1]
                    }}
                  </span>
                  <span class="interval">/month</span>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-if="
                  plan.image === true &&
                    getPlan(plan.id)[0].offerImage['_180wide']
                "
                class="offerBlk"
              >
                <img
                  class="offerImageDesk"
                  :src="getPlan(plan.id)[0].offerImage['_180wide']"
                />
                <img
                  class="offerImageMobile"
                  :src="getPlan(plan.id)[0].offerImage['_282wide']"
                  alt="loading..."
                />
              </div>
              <div v-if="typeof plan.image === 'string'" class="offerBlk">
                <img
                  class="offerImageDesk"
                  :src="plan.image + '.png'"
                >
                <img
                  class="offerImageMobile"
                  :src="plan.image + '-mobile.png'"
                >
              </div>
              <div
                class="cardWrap"
                v-else-if="
                  !plan.image || !getPlan(plan.id)[0].offerImage['_180wide']
                "
              >
                <span class="currency">{{
                  getCurrency(getPlan(plan.id)[0].currency)
                }}</span>
                <span class="dollars">
                  {{
                    getPlan(plan.id)[0]
                      .amount.toString()
                      .split(".")[0]
                  }}
                </span>
                <div class="centsWrapper">
                  <span class="cents">
                    {{
                      getPlan(plan.id)[0]
                        .amount.toString()
                        .split(".")[1]
                    }}
                  </span>
                  <span class="interval">/month</span>
                </div>
              </div>
            </template>
          </div>
          <p
            class="wow"
            v-if="
              (plan.wow && !plan.image) ||
                (typeof plan.image === 'boolean' && !getPlan(plan.id)[0].offerImage['_180wide'])
            "
          >
            BEST DEAL
            <br />SAVE 50%
          </p>
        </div>


        <div
          v-if="!plan.image || (typeof plan.image === 'boolean' && !getPlan(plan.id)[0].offerImage['_180wide'])"
          class="bottomWrapper"
        >
          <p class="fullPrice">
            {{ getPlan(plan.id)[0].amount }}
            {{ getBilling(getPlan(plan.id)[0].interval) }}
          </p>
          <div class="neouButton">Select Plan</div>
          <p class="bottom">
            <span class="trial" v-if="plan.trial > 0"
              >{{ plan.trial }}-day free trial</span
            >
            <span class="cancel">Cancel Anytime</span>
          </p>
        </div>
      </div>
      <template v-if="flashSale && offer[0].id == 'price_1IUFtHDej2Rk1s0KynsbhvEn'">
              <div class="planWrapper planWrapperNo">
                <div class="offerBlk">
                <img
                    class="offerImageDesk"
                    src="https://get.neoufitness.com/img/grey-annual.png"
                  >
                <img
                  class="offerImageMobile"
                  src="https://get.neoufitness.com/img/grey-annual-mobile.png"
                >
               </div>
             </div>
               <div class="planWrapper planWrapperNo">
                <div class="offerBlk">
                <img
                    class="offerImageDesk"
                    src="https://get.neoufitness.com/img/grey-semi-annual.png"
                  >
                <img
                  class="offerImageMobile"
                  src="https://get.neoufitness.com/img/grey-semi-annual-mobile.png"
                >
               </div>
             </div>
               <div class="planWrapper planWrapperNo">
                <div class="offerBlk">
                <img
                    class="offerImageDesk"
                    src="https://get.neoufitness.com/img/grey-monthly.png"
                  >
                <img
                  class="offerImageMobile"
                  src="https://get.neoufitness.com/img/grey-monthly-mobile.png"
                >
               </div>
             </div>
              </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import "@/assets/tweaked-vue-material.min.css";
import { MdProgress } from "vue-material/dist/components";

import PlanVariantA from "./PlanVariantA.vue";
import PlanVariantB from "./PlanVariantB.vue";
// import TabPlans from "./VueTabs.vue";

Vue.use(MdProgress);

export default {
  // name: "Plans",
  data: () => ({
    fetchingPlans: true,
    plans: {},
    offer: window.planOffers.plans,
    variant: "",
    flashSale: window.flashSale
  }),
  components: { PlanVariantAsec: PlanVariantA, PlanVariantBsec: PlanVariantB },
  beforeMount() {
    const getVariant = this.parse(window.location.search);
    this.variant = getVariant.variant;
    axios
      .get(
        "https://api.neoufitness.com/neou/v1/subscription/plan?isMarketing=true",
        {
          headers: {
            Authorization: "JWT " + this.$cookie.get("token"),
            token: this.$cookie.get("getUserToken")
          }
        }
      )
      .then(res => {
        console.log(res.data.result.products);
        this.fetchingPlans = false;
        this.plans = res.data.result.products;
        this.isPlanSelected();
        // Track View Plans
        window.analytics.track("View Plans", {
          products_displayed: this.offer.map(plan => plan.id)
        });
      })
      .catch(err => {
        console.log(err);
      });
    window.dataLayer.push({
      event: "view_plans"
    });
    // Load Stripe if we dont have stripe loaded
    if( typeof window.Stripe == "undefined" ) {
      var scripts = [
        "https://js.stripe.com/v3/",
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    }
  },
  methods: {
    parse(queryString) {
      var query = {};
      var pairs = (queryString[0] === "?"
        ? queryString.substr(1)
        : queryString
      ).split("&");
      for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
      }
      return query;
    },
    childCallBack(selectedPlan, title) {
      selectedPlan[0]["name"] = title;
      this.$emit("selectedPlan", selectedPlan[0]);
      window.dataLayer.push({
        event: "select_plan",
        plan_id: selectedPlan[0].id
      });
      this.$emit("nextScreen");
    },
    handlePlanSelected: function(selectedPlan, title) {
      // if (selectedPlan.length == 1) {
      //   selectedPlan = selectedPlan[0];
      // }
      selectedPlan[0]["name"] = title;
      this.$emit("selectedPlan", selectedPlan[0]);
      window.dataLayer.push({
        event: "select_plan",
        plan_id: selectedPlan[0].id
      });
      // Track Plan Selected
      window.analytics.track("Select Plan", {
        products_displayed: this.offer.map(plan => plan.id),
        selected_product: selectedPlan[0].id
      });
      // Annual or Monthly
      if (selectedPlan[0].trial_period_days > 0) {
        window.analytics.track("Select Plan Trial");
        window.dataLayer.push({
          event: "select_plan_trial"
        });
      } 
      if( selectedPlan[0].interval_count > 1 && selectedPlan[0].interval == "month") {
        window.analytics.track("Select Plan Six Month");
        window.dataLayer.push({
          event: "select_plan_six_month"
        });
      }
      if( selectedPlan[0].interval_count == 1 && selectedPlan[0].interval == "month") {
        window.analytics.track("Select Plan Monthly");
        window.dataLayer.push({
          event: "select_plan_monthly"
        });
      }
      this.$emit("nextScreen");
    },
    getPlan: function(planToFind) {
      let prices = [];
      this.plans.forEach(plan => {
        prices.push(...plan.plans);
      });
      return prices.filter(price => {
        return price.id == planToFind;
      });
    },
    getBilling: function(interval) {
      if (interval == "year") {
        return "billed annually";
      }
      if (interval == "month") {
        return "billed monthly";
      }
    },
    getCurrency: function(currency) {
      if (currency == "usd") {
        return "$";
      }
      if (currency == "gbp") {
        return "£";
      }
      if (currency == "cad") {
        return "$";
      }
      if (currency == "aud") {
        return "$";
      }
    },
    isPlanSelected: function() {
      if (
        typeof window.selectedPlan !== undefined &&
        window.preserveCheckout !== false
      ) {
        // this.handlePlanSelected(
        //   this.getPlan(window.selectedPlan.id),
        //   window.selectedPlan.name
        // );
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.offerImageDesk {
  width: 150px;
  display: none;
}
.offerImageMobile {
  width: 100%;
}
.planWrapperAll {
  background: red;
}
.offerBlk {
  cursor: pointer;
  // padding: 10px;
  display: flex;
  align-items:center;
}
.cardWrap {
  display: flex;
  align-items: center;
}
.md-progress-spinner {
  stroke: #56fffe;
}
.cancel {
  padding-left: 5px;
}

.loading {
  text-align: center;
  margin-top: 10vh;
}
.checkoutBoxInner {
  width: 100%;
  max-width: 290px;
  margin: 0px auto;
}
.plan {
  cursor: pointer;
}
.select {
  text-align: center;
  font-weight: 400;
  color: #56fffe;
  font-size: 16px;
  font-family: "prox", Helvetica, Arial, sans-serif;
}

.neouLogo {
  width: 115px;
  margin: 0px auto;
  display: block;
}

.logoSub {
  text-align: center;
}

.planWrapper {
  border: 1px solid #35414a;
  border-radius: 12px;
  color: #ffffff;
  padding: 22px 0px;
  text-align: center;
  width: 100%;
  background: #12161a;
  margin-top: 20px;
  &.planWrapperNo {
    border: none;
    background: none;
    width: 100%;
    padding: 0;
  }
  &.planWrapperNoYear {
    border: none !important;
    background: none !important;
    width: 100% !important;
    padding: 0;
    .priceGroup {
      margin-left: 0 !important;
    }
  }
  .planHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .priceGroup {
    display: flex;
    align-items: center;
  }

  .currency {
    font-size: 55px;
    font-weight: 500;
    letter-spacing: -5px;
    margin-left: -5px;
  }
  .dollars {
    font-size: 55px;
    font-weight: 500;
    margin-right: 5px;
  }
  .centsWrapper {
    font-weight: bold;
    .cents {
      font-size: 24px;
      display: block;
    }
    .interval {
      font-size: 18px;
      display: block;
    }
  }

  .wow {
    background-color: #222a31;
    font-size: 18px;
    line-height: 1.125em;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 10px;
    color: #ffffff;
    position: relative;
    margin: 0px;

    &::before {
      display: block;
      content: "";
      border-top: 24px solid transparent;
      border-right: 12px solid #222a31;
      border-bottom: 24px solid transparent;
      border-left: 0px solid transparent;
      position: absolute;
      right: 100%;
      top: 0px;
      bottom: 0px;
    }
  }

  .bottomWrapper {
    padding: 10px 25px 0px;
  }

  .fullPrice {
    color: #ffffff;
    font-size: 14px;
    margin: 0px;
    padding-bottom: 10px;
  }

  .neouButton {
    color: #12161a;
    letter-spacing: 1px;
    font-size: 15px;
    line-height: 48px;
    height: 48px;
  }

  .bottom {
    margin: 20px 0px 0px 0px;
  }

  .trial {
    font-weight: bold;
    text-transform: uppercase;
  }

  &.primary {
    background: #ffffff;
    color: #12161a;
    border: 3px solid #56fffe;
    .planHeader {
      justify-content: space-between;
    }

    .fullPrice {
      color: #12161a;
      font-size: 14px;
    }
  }

  &.hasWow {
    &.planWrapperNo {
      .priceGroup {
        margin-left: 0;
      }
      &.month {
        padding: 0;
      }
    }
    &.month {
      padding: 28px 0;
    }
    .priceGroup {
      margin-left: 20px;
    }
  }
}
@media screen and (min-width: 768px) {
  .checkoutBoxInner {
    max-width: 768px;
  }

  .plansDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .planWrapper {
    max-width: 288px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 10px;
    &.planWrapperNoYear {
      max-width: 180px !important;
      align-items: center;
    }
    &.planWrapperNo {
      max-width: 180px;
      align-items: flex-start;
    }
  }
  .offerImageDesk {
    display: block;
    width: 100%;
    max-width: 180px;
  }
  .offerImageMobile {
    display: none;
  }
  .cardSec {
    flex-direction: row;
  }
}
@media screen and (max-width: 768px) {
  .planWrapper {
    &.planWrapperNoYear {
      padding: 0;
    }
    // &.planWrapperNo {
    //   padding: 0;
    // }
    &.hasWow {
      &.planWrapperNo {
        padding: 0;
        &.month {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .planWrapper {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 10px;

    &.hasWow {
      &.planWrapperNo {
        .priceGroup {
          margin-left: 0;
        }
      }
      .priceGroup {
        margin-left: 50px;
      }
    }

    .wow {
      padding-right: 50px;
    }

    .bottom {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 640px) {
  .checkoutBoxInner {
    width: 100%;
    max-width: 100%;
  }
}
</style>
